<template>

    <div class="modal" tabindex="-1" role="dialog"
         ref="notificacoesModal" data-backdrop="static">

        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">

                <div class="modal-header">
                    <h3 class="modal-title">{{ selectedNotificacao.titulo }}</h3>
                </div>

                <div class="modal-body">

                    <div class="row">

                        <div class="col-sm-12">

                            <p v-html="selectedNotificacao.texto"></p>

                        </div>
                        <hr/>
                        <div v-if="selectedNotificacao.sysfilename" class="col-sm-12">

                            <a :href="downloaFileUrl">
                                {{ selectedNotificacao.filename }}
                            </a>

                        </div>

                    </div>

                </div>

                <div class="modal-footer">
                    <button class="btn btn-lg btn-success"
                            @click="markNotificacao">
                        Li e compreendi
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {handleAjaxError} from "@/utils";


export default {
    name: "NotificacoesModal",
    components: {},

    data() {
        return {
            notificacoesList: [],
            selectedNotificacao: {},
        }
    },

    mounted() {
        // console.debug("THERE YOU GO!!!");
        this.loadNotificacoes();
    },

    computed: {

        downloaFileUrl() {
            if (!this.selectedNotificacao && !this.selectedNotificacao.sysfilename) {
                return '#';
            }
            // eslint-disable-next-line
            return jsRoutes.controllers.ConcNotificacoesController.downloadNotificacaoFile(this.selectedNotificacao.id).url
        }

    },


    methods: {

        loadNotificacoes() {
            // eslint-disable-next-line
            const url = jsRoutes.controllers.ConcNotificacoesController.loadNotificacoesUser().url;
            axios.get(url)
                .then(response => {
                    // console.debug(response);
                    this.notificacoesList = response.data;
                    this.displayNotificacao();
                })
                .catch(error => {
                    // console.error('load Notificacoes error ', error);
                    handleAjaxError(error)
                });
        },

        displayNotificacao() {
            if (this.notificacoesList && this.notificacoesList.length > 0) {
                this.selectedNotificacao = this.notificacoesList.shift();
                $(this.$refs.notificacoesModal).modal('show');
            }
        },

        markNotificacao() {
            $(this.$refs.notificacoesModal).modal('hide');

            const markCommand = {
                id: this.selectedNotificacao.id
            };

            // eslint-disable-next-line
            const url = jsRoutes.controllers.ConcNotificacoesController.marcarNotificacaoLida().url;

            axios.post(url, markCommand)
                .then(() => {
                    this.selectedNotificacao = {};
                    this.displayNotificacao();
                })
                .catch(error => {
                    handleAjaxError(error)
                });
        },


    }
}
</script>

<style scoped>

</style>
