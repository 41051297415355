import Vue from 'vue'
import VueRouter from 'vue-router'
import ImpNotificacoesHome from "@/notificacoesimp/ImpNotificacoesHome.vue";
import ImpNotificacoesCreate from "@/notificacoesimp/ImpNotificacoesCreate.vue";
import ImpNotificacoesEdit from "@/notificacoesimp/ImpNotificacoesEdit.vue";

Vue.use(VueRouter)

export default new VueRouter({
	routes: [
		{
			path: '/',
			name: 'ImpNotificacoesHome',
			component: ImpNotificacoesHome
		},
		{
			path: '/criar',
			name: 'ImpNotificacoesCreate',
			component: ImpNotificacoesCreate
		},
		{
			path: '/:id/editar',
			name: 'ImpNotificacoesEdit',
			props: true,
			component: ImpNotificacoesEdit
		},

	]
})
