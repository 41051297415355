import Dropzone from "dropzone";
import './assets/css/styles.css'

import Vue from 'vue'
import VTooltip from 'v-tooltip'
import Vuelidate from 'vuelidate';
import axios from "axios";
import ImpAcessoriosApp from "./acessoriosimp/ImpAcessoriosApp";
import impAcessoriosRouter from "@/acessoriosimp/impAcessoriosRouter";
import ConcAcessoriosApp from "./acessoriosconc/ConcAcessoriosApp";
import concAcessoriosRouter from "@/acessoriosconc/concAcessoriosRouter";
import DescodificadorChassis from "@/campanhasimp/DescodificadorChassis.vue";
import ImpCampanhasFicheiros from "@/campanhasimp/ImpCampanhasFicheiros.vue";
import impNotificacoesRouter from "@/notificacoesimp/impNotificacoesRouter";
import ImpNotificacoesModule from "@/notificacoesimp/ImpNotificacoesModule.vue";
import NotificacoesModal from "@/notificacoesconc/NotificacoesModal.vue";

Dropzone.autoDiscover = false;

// eslint-disable-next-line
moment.locale("pt");

Vue.config.productionTip = false

Vue.use(VTooltip)

Vue.use(Vuelidate);


if (document.getElementById("concessionario_notificacoes_component")) {
	new Vue({
		render: h => h(NotificacoesModal)
	}).$mount('#concessionario_notificacoes_component');
}


if (document.getElementById("imp_notificacoes_container")) {
	new Vue({
		router: impNotificacoesRouter,
		render: h => h(ImpNotificacoesModule)
	}).$mount('#imp_notificacoes_container');
}

if (document.getElementById("imp_descodificador_chassis")) {
	new Vue({
		// router: concAcessoriosRouter,
		render: h => h(DescodificadorChassis)
	}).$mount('#imp_descodificador_chassis');
}

if (document.getElementById("concAcessoriosContainer")) {
	new Vue({
		router: concAcessoriosRouter,
		render: h => h(ConcAcessoriosApp)
	}).$mount('#concAcessoriosContainer');
}

if (document.getElementById("impAcessoriosContainer")) {
	new Vue({
		router: impAcessoriosRouter,
		render: h => h(ImpAcessoriosApp)
	}).$mount('#impAcessoriosContainer');
}

if (document.getElementById("impFicheirosCampanhasContainer")) {
	new Vue({
		render: h => h(ImpCampanhasFicheiros)
	}).$mount('#impFicheirosCampanhasContainer');
}


if (document.getElementById("concPartial")) {
	new Vue({
		el: '#concPartial',
		data: {
			concId: null,
			concessionarios: [],
			teste: [
				{
					id: 1,
					text: "teste 1"
				},
				{
					id: 2,
					text: "teste 2"
				},
			],
			firstLoad: true
		},
		mounted: function () {
			this.loadDetails();

		},
		watch: {
			concId: function () {
				if (!this.firstLoad) {
					// eslint-disable-next-line
					const url = jsRoutes.controllers.Application.updateCurrentConcessionario(this.concId).url;
					axios.get(url).then(function () {
						// success callback
						// eslint-disable-next-line
						reloadPage();
					}, function () {
						// eslint-disable-next-line
						showError("Erro", "Erro ao mudar de concessionário");

					});
				} else {
					this.firstLoad = false;
				}
			}
		},
		methods: {
			loadDetails: function () {
				const self = this;
				// eslint-disable-next-line
				const url = jsRoutes.controllers.Application.loadUserConcessionarioDetails().url;

				axios.get(url).then(function (response) {
					// success callback
					self.concId = response.data.concId;
					self.concessionarios = response.data.concessionarios;
				}, function () {
					// eslint-disable-next-line
					showError("Erro", "Erro ao carregar detalhes de concessionário");
				});
			},
		},
	})
}
