<template>
    <div class="col-sm-12">
        <div v-show="loading">
            <i class="fa fa-spin fa-spinner fa-2x"></i>
        </div>
        <div v-show="!loading">
            <div class="col-sm-12">
                <h4>
                    <router-link
                        :to="{name :'ConcAcessoriosModelo', params: {modeloId: detalhe.modeloId, modeloNome: detalhe.modeloNome}}">
                        <i class="fa fa-arrow-left"></i> Voltar
                    </router-link>
                </h4>
                <h3>Acessórios</h3>
                <hr/>
            </div>
            <div class="col-sm-12 col-md-6">
                <carousel :images="imagens"></carousel>
            </div>
            <div class="col-sm-12 col-md-6">
                <h2 style="margin-top: 0;">{{detalhe.nome}}</h2>
                <h5 v-if="detalhe.categoria"><b>Categoria</b>: {{ detalhe.categoriaNome }}</h5>
                <h5><b>REF</b>: {{detalhe.ref}}</h5>
                <h5 v-if="detalhe.preco"><b>Preço</b>: {{ detalhe.preco }}</h5>
                <h5 v-if="detalhe.maoObra"><b>Mão de Obra</b>: {{ detalhe.maoObra }}</h5>
                <p v-html="detalhe.descricao"></p>
                <hr/>
                <ul class="list-group">
                    <li v-for="ficheiro in detalhe.ficheiros" :key="ficheiro.id" class="list-group-item row">
                        <div class="col-xs-12" style="margin-top: 5px;">
                            <p>
                                <i class="fa fa-2x" :class="icon" v-for="(icon, idx) in getIconsFromText(ficheiro.icons)"
                                   :key="idx" aria-hidden="true"></i>
                                <span class="mleft-5" v-html="fixNewlines(ficheiro.descricao)"></span>
                            </p>
                        </div>

                        <div class="col-xs-12">
                            <i class="fa fa-2x" :class="getFileType(ficheiro.filename)"></i>&nbsp;&nbsp;&nbsp;

                            <a :href="acessorioFicheiroUrl(ficheiro.id)">
                                <i class="fa fa-2x fa-download"></i> {{ ficheiro.filename }}
                            </a>
                        </div>

                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<style scoped>

</style>
<script>
// @ is an alias to /src
import axios from "axios";
import Carousel from "@/components/Carousel";

export default {
    components: {
        Carousel
    },
    props: ["id"],
    data() {
        return {
            detalhe: {
                assunto: null,
                descricao: null,
                dataCriacao: null,
                respostas: [],
                ficheiros: [],
            },

            imagens: [],

            loading: false,

        }
    },
    mounted() {
        this.loadDetalhe();
    },
    computed: {},
    methods: {

        fixNewlines(text) {
            if (text) {
                return text.replaceAll('\n', '<br>');
            }
            return '';
        },
        getIconsFromText(value) {
            if (!value) {
                return []
            }
            return value.split(',');
        },

        getFileType(filename) {
            if (filename.endsWith('xls') || filename.endsWith('xlsx')) {
                return 'fa-file-excel-o text-success'
            }
            return 'fa-file-pdf-o text-danger'
        },

        acessorioFicheiroUrl(id) {
            // eslint-disable-next-line
            return jsRoutes.controllers.ConcAcessoriosController.acessorioFicheiro(id).url;
        },
        loadDetalhe() {
            this.loading = true;
            const self = this;
            // eslint-disable-next-line
            const url = jsRoutes.controllers.ConcAcessoriosController.getDetalheAcessorio(this.id).url;

            // GET request
            axios.get(url)
                .then(function (response) {
                    console.debug(response.data);
                    self.loading = false;
                    // set data
                    // eslint-disable-next-line
                    self.detalhe = response.data;
                    self.imagens = [];
                    self.detalhe.imagens.forEach(fid => {
                        self.imagens.push(self.acessorioFicheiroUrl(fid));
                    })
                })
                .catch(function (error) {
                    // eslint-disable-next-line
                    handleAjaxError("Erro ao obter detalhe de acessório", error);
                })
                .finally(() => {
                    self.loading = false;

                });
        },
    }
}
</script>
