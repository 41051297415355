<template>
    <div class="col-sm-12">
        <div v-show="loading">
            <i class="fa fa-spin fa-spinner fa-2x"></i>
        </div>
        <div v-show="!loading">
            <div class="col-sm-12">
                <h4>
                    <router-link
                        :to="{name :'ImpAcessoriosModelo', params: {modeloId: detalhe.modeloId, modeloNome: detalhe.modeloNome}}">
                        <i class="fa fa-arrow-left"></i> Voltar
                    </router-link>
                    <button class="btn btn-primary" @click="showEditar = true">
                        <i class="fa fa-edit"></i> Editar
                    </button>
                </h4>
            </div>
            <div class="col-sm-12 col-md-6">
                <carousel :images="imagens"></carousel>
                <!--                <carousel :per-page="1" :autoplay="true">
                                    <slide v-for="id in detalhe.ficheiros" :key="id">
                                        <img style="width:100%;" :src="acessorioFicheiroUrl(id)">
                                    </slide>
                                </carousel>-->
            </div>
            <div class="col-sm-12 col-md-6">
                <h2 style="margin-top: 0;">{{ detalhe.nome }}</h2>
                <h5 v-if="detalhe.categoria"><b>Categoria</b>: {{ detalhe.categoriaNome }}</h5>
                <h5><b>REF</b>: {{detalhe.ref}}</h5>
                <h5 v-if="detalhe.preco"><b>Preço</b>: {{ detalhe.preco }}</h5>
                <h5 v-if="detalhe.maoObra"><b>Mão de Obra</b>: {{ detalhe.maoObra }}</h5>
                <p v-html="detalhe.descricao"></p>
                <hr/>
                <ul class="list-group">
                    <li v-for="ficheiro in detalhe.ficheiros" :key="ficheiro.id" class="list-group-item row">
                        <div class="col-xs-12" style="margin-top: 5px;">
                            <p>
                                <i class="fa fa-2x" :class="icon" v-for="(icon, idx) in getIconsFromText(ficheiro.icons)"
                                   :key="idx" aria-hidden="true"></i>
                                <span class="mleft-5" v-html="fixNewlines(ficheiro.descricao)"></span>
                            </p>
                        </div>
                        <div class="col-xs-11">
                            <i class="fa fa-2x" :class="getFileType(ficheiro.filename)"></i>&nbsp;&nbsp;&nbsp;
                            <a :href="acessorioFicheiroUrl(ficheiro.id)">
                                <i class="fa fa-2x fa-download"></i> {{ ficheiro.filename }}
                            </a>
                        </div>
                        <div class="col-xs-1">
                            <button class="btn btn-primary btn-sm"
                                    @click="openEditFile(ficheiro)">
                                <i class="fa fa-edit"></i>
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <editar-acessorio-modal :show.sync="showEditar"
                                :acessorio="detalhe"
                                :categorias="categorias"
                                @refresh="loadDetalhe"></editar-acessorio-modal>

        <editar-ficheiro-data-modal :show.sync="showEditarFicheiro"
                                    @refresh="loadDetalhe"
                                    :file="selectedFile"></editar-ficheiro-data-modal>

    </div>
</template>
<style scoped>

</style>
<script>
// @ is an alias to /src
import axios from "axios";
import EditarAcessorioModal from "@/acessoriosimp/modal/EditarAcessorioModal";
import Carousel from '@/components/Carousel';
import EditarFicheiroDataModal from "./modal/EditarFicheiroDataModal.vue";
import {handleAjaxError} from "@/utils";

export default {
    components: {
        EditarFicheiroDataModal,
        EditarAcessorioModal,
        Carousel
    },
    props: ["id"],
    data() {
        return {
            detalhe: {
                modeloId: null,
                assunto: null,
                descricao: null,
                dataCriacao: null,
                respostas: [],
                ficheiros: [],
            },

            imagens: [],

            loading: false,

            showEditar: false,

            showEditarFicheiro: false,

            selectedFile: null,
            categorias: [],
        }
    },
    mounted() {
        this.loadDetalhe();
        this.loadCategorias();
    },
    computed: {},
    methods: {

        fixNewlines(text) {
            if (text) {
                return text.replaceAll('\n', '<br>');
            }
            return '';
        },
        getIconsFromText(value) {
            if (!value) {
                return []
            }
            return value.split(',');
        },
        acessorioFicheiroUrl(id) {
            // eslint-disable-next-line
            return jsRoutes.controllers.ImpAcessoriosController.acessorioFicheiro(id).url;
        },
        loadCategorias() {

            // eslint-disable-next-line
            const url = jsRoutes.controllers.ImpAcessoriosController.loadCategoriasAcessorios().url;
            axios.get(url)
                .then(response => {
                    // console.debug(response)
                    const list = response.data.map(value => ({id: value.id, text: value.nome}));
                    list.unshift({id: null, text: '-- Sem categoria --'})
                    this.categorias = list;
                })
                .catch(error => {
                    console.error(error)
                    handleAjaxError('Erro', error);
                })

        },
        loadDetalhe() {
            this.loading = true;
            const self = this;
            // eslint-disable-next-line
            const url = jsRoutes.controllers.ImpAcessoriosController.getDetalheAcessorio(this.id).url;

            // GET request
            axios.get(url)
                .then(function (response) {
                    // eslint-disable-next-line
                    self.detalhe = response.data;
                    self.imagens = [];
                    self.detalhe.imagens.forEach(fid => {
                        self.imagens.push(self.acessorioFicheiroUrl(fid));
                    })
                })
                .catch(function (error) {
                    // eslint-disable-next-line
                    handleAjaxError("Erro ao obter detalhe de acessório", error);
                })
                .finally(() => {
                    self.loading = false;
                });
        },

        openEditFile(ficheiro) {
            this.selectedFile = ficheiro;
            this.showEditarFicheiro = true;
        },

        getFileType(filename) {
            if (filename.endsWith('xls') || filename.endsWith('xlsx')) {
                return 'fa-file-excel-o text-success'
            }
            return 'fa-file-pdf-o text-danger'
        }
    }
}
</script>
