<template>
    <div class="modal" tabindex="-1" role="dialog"
         ref="notificacoesLidasModal">

        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">

                <div class="modal-header">
                    <h3 class="modal-title">Histórico de Leitura</h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button>
                </div>

                <div class="modal-body">

                    <div class="table-responsive" style="font-size: smaller;">
                        <table class="table table-bordered table-striped table-hover"
                               id="imp_notificacoes_lidas_modal_table">
                            <thead>
                            <tr>
                                <th>Data Leitura</th>
                                <th>Utilizador</th>
                                <th>Concessionário</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in list" :key="item.id">
                                <td>{{ item.data }}</td>
                                <td>{{ item.userName }}</td>
                                <td>{{ item.concessionario }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Fechar</button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import {datatableLanguage, handleAjaxError} from "@/utils";
import axios from "axios";

export default {
    name: "NotificacoesLidasModal",

    props: {
        show: {
            type: Boolean,
            required: true,
        },
        id: {
            type: Number,
            required: false
        }
    },
    data() {
        return {
            list: [],
        }
    },

    mounted() {
        const self = this;
        // eslint-disable-next-line
        $(this.$refs.notificacoesLidasModal).on('hidden.bs.modal', function () {
            self.close()
        });
    },

    watch: {
        show() {
            if (this.show) {
                // eslint-disable-next-line
                $(this.$refs.notificacoesLidasModal).modal('show');
                this.loadLog();
            } else {
                // eslint-disable-next-line
                $(this.$refs.notificacoesLidasModal).modal('hide');
            }
        },
    },

    methods: {
        close() {
            this.$emit('update:show', false);
        },

        loadLog() {
            this.list = [];

            // eslint-disable-next-line
            const url = jsRoutes.controllers.ImpNotificacoesController.loadNotificacao(this.id).url;

            axios.get(url)
                .then(response => {
                    // console.debug(response);
                    this.list = response.data.lidas;
                    const dataTable = $('#impNotificacoesTable').DataTable();
                    if (dataTable) {
                        dataTable.destroy();
                    }
                    this.$nextTick(() => {
                        const dataTable = $('#imp_notificacoes_lidas_modal_table').DataTable();
                        if (dataTable) {
                            dataTable.destroy();
                        }
                        this.setupDatatable();
                    });
                })
                .catch(error => {
                    handleAjaxError(error, 'Erro!');
                })

        },

        setupDatatable() {
            $.fn.dataTable.moment('DD-MM-YYYY HH:mm');

            $('#imp_notificacoes_lidas_modal_table').DataTable({
                "info": false,
                "paging": true,
                "pageLength": 40,
                "searching": true,
                "ordering": true,
                "order": [0, "desc"],
                buttons: [
                    'excel',
                    {
                        extend: 'pdf',
                        orientation: 'landscape',
                        pageSize: 'A4'
                    },
                ],
                "dom": 'Bfrtp',
                "language": datatableLanguage,
            });
        },
    },
}
</script>

<style scoped>

</style>
