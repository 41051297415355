<template>
    <div class="modal" tabindex="-1" role="dialog" ref="modal">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button>
                    <h5 class="modal-title">Criar Acessório</h5>
                </div>
                <div class="modal-body">
                    <form novalidate v-on:submit.prevent="criarAcessorio">
                        <div class="form-group" :class="{'has-error' : $v.nome.$error}">
                            <label>Nome *</label>
                            <input type="text" class="form-control" v-model="nome"/>
                            <span class="help-block"
                                  v-show="!$v.nome.required && $v.nome.$error">Campo obrigatório</span>
                            <span class="help-block"
                                  v-show="!$v.nome.maxLength && $v.nome.$error">O campo tem de ter menos de 100 caracteres</span>
                        </div>
                        <div class="form-group" :class="{'has-error' : $v.ref.$error}">
                            <label>ref *</label>
                            <input type="text" class="form-control" v-model="ref"/>
                            <span class="help-block"
                                  v-show="!$v.ref.required && $v.ref.$error">Campo obrigatório</span>
                            <span class="help-block"
                                  v-show="!$v.ref.maxLength && $v.ref.$error">O campo tem de ter menos de 45 caracteres</span>
                        </div>

                        <div class="form-group">
                            <label>Preço</label>
                            <input type="text" class="form-control" v-model="preco"/>
                        </div>

                        <div class="form-group">
                            <label>Mão de Obra</label>
                            <input type="text" class="form-control" v-model="maoObra"/>
                        </div>

                        <div class="form-group">
                            <label>Categoria</label>
                            <b-select :items="categorias"
                                      :value.sync="categoria"
                                      data-live-search="true" data-width="100%"></b-select>
                        </div>

                        <div class="form-group" :class="{'has-error' : $v.descricao.$error}">
                            <label>Descrição</label>
                            <input type="hidden" class="form-control" v-model="descricao"/>
                            <VueEditor v-model="descricao"/>
                            <span class="help-block"
                                  v-show="!$v.descricao.required && $v.descricao.$error">Campo obrigatório</span>
                        </div>
                        <div class="form-group" :class="{'has-error' : showFilesError}">
                            <label>Ficheiros</label>
                            <div class="dropzone" ref="dropzonearea">
                                <div class="dz-message">
                                    <h3>Arrastar ficheiros para carregar</h3><br>
                                    <span class="note">(Ou clicar com o rato para selecionar ficheiros)</span>
                                </div>
                                <!--                                    <select id="filesData" name="files" multiple="multiple" hidden>
                                                                </select>-->
                            </div>
                            <span class="help-block"
                                  v-show="showFilesError">Adicione pelo menos um ficheiro</span>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
                    <v-button type="button" class="btn btn-primary"
                              :loading="loading"
                              v-on:click.prevent="criarAcessorio">
                        Criar Acessorio
                    </v-button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Dropzone from "dropzone";
import axios from 'axios'
import VButton from "../../components/VButton";
import {maxLength, required} from "vuelidate/lib/validators";
import {handleAjaxError} from "@/utils";
import {VueEditor} from "vue2-editor";
import BSelect from "@/components/BSelect.vue";

Dropzone.autoDiscover = false;

export default {
    components: {
        BSelect,
        VButton,
        VueEditor,
    },
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        modeloId: {
            type: [Number, String],
            required: true
        },
        categorias: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            loading: false,

            nome: null,
            ref: null,
            descricao: null,
            showFilesError: false,
            categoria: null,
            maoObra: null,
            preco: null,


        }
    },
    validations: {
        nome: {
            required,
            maxLength: maxLength(45),
        },
        ref: {
            required,
            maxLength: maxLength(100),
        },
        descricao: {
            required,
        },
    },
    mounted() {
        let self = this;

        this.setupDropzone();

        // eslint-disable-next-line
        $(this.$refs.modal).on('hidden.bs.modal', function (e) {
            self.close()
        });

    },
    watch: {
        show() {
            if (this.show) {
                // eslint-disable-next-line
                this.nome = null;
                this.ref = null;
                this.descricao = null;
                this.showFilesError = false;
                $(this.$refs.modal).modal('show');
            } else {
                $(this.$refs.modal).modal('hide');
            }
        },
    },
    computed: {},
    methods: {
        close() {
            this.$emit('update:show', false);
        },
        setupDropzone: function () {
            const self = this;
            this.dropzone = new Dropzone(this.$refs.dropzonearea, {
                url: "url", //dropzone.js:434 Uncaught Error: No URL provided
                paramName: "file", // The name that will be used to transfer the file
                maxFilesize: 50, // MB
                autoProcessQueue: false,
                uploadMultiple: true,
                addRemoveLinks: true,
                //acceptedFiles: ".jpg, .jpeg, .png, .bmp, .tif, .tiff",
                error: function (file, errorMessage) {//if the error was due to the XMLHttpRequest the xhr object as third
                    // eslint-disable-next-line
                    showError("Erro", errorMessage);
                    // self.uploadSuccess = false;
                    this.removeAllFiles(true);
                    self.dropzone.processQueue();
                },

                success: function (file/*, response*/) {
                    // console.log("#### success");
                    // self.uploadSuccess = true;
                    // self.files.push(response);
                    self.dropzone.processQueue();
                    this.removeFile(file);
                },

                queuecomplete: function () {
                    console.log("#### queuecomplete");
                    // if (self.uploadSuccess) {
                    //     // showSuccess("Sucesso", "Ficheiro carregado");
                    //     if (self.files.length > 0) {
                    //         self.filesValid = true;
                    //     }
                    // }
                },
            });
            this.dropzone.on("addedfile", (/*file*/) => {
                // console.log("A file has been added");
                self.showFilesError = false;
            });
            // this.dropzoneSetup = true;
        },
        criarAcessorio() {
            const self = this;
            this.$v.$touch()
            this.showFilesError = this.dropzone.files.length === 0;

            if (this.$v.$invalid || this.showFilesError) {
                console.error('data invalid');
                return;
            }


            let formData = new FormData();
            formData.append("modeloId", this.modeloId + "");
            formData.append("nome", this.nome);
            formData.append("ref", this.ref);
            formData.append("descricao", this.descricao);
            formData.append("categoria", this.categoria);
            formData.append("maoObra", this.maoObra);
            formData.append("preco", this.preco);

            const files = this.dropzone.files;
            files.forEach(function (value, index) {
                formData.append("ficheiro_" + index, value);
            });

            self.loading = true;
            // eslint-disable-next-line
            const url = jsRoutes.controllers.ImpAcessoriosController.criarAcessorio().url;

            axios.post(url, formData).then(function () {
                self.loading = false;
                // eslint-disable-next-line
                showSuccess('Sucesso', 'Acessório Criado');
                self.$emit('refresh');
                self.close();
            }, function (error) {
                self.loading = false;
                // eslint-disable-next-line
                handleAjaxError(error, 'Ocorreu um erro ao criar acessório');
            });


        },
    }
}
</script>

<style scoped>

</style>
