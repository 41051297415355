<template>
    <div class="col-sm-12">
        <div class="panel panel-default">
            <div class="panel-heading">
                <h3>Ficheiros</h3>
            </div>
            <div class="panel-body">
                <div class="col-md-12 " id="dropzonearea" ref="dropzonearea">
                    <div class="dz-message">
                        <h3>Arrastar ficheiros para carregar</h3><br>
                        <span class="note">(Ou clicar com o rato para selecionar ficheiros)</span>
                    </div>
                    <select id="filesData" name="files" multiple="multiple" hidden>
                    </select>
                </div>
            </div>
            <div v-if="files.length > 0">
                <div class="mb-3">
                    <input type="text" class="form-control" v-model="filterText" placeholder="Filtrar" />
                </div>
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nome</th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="file in filteredFiles" :key="file.id">
                        <td>{{ file.id }}</td>
                        <td>
                            <i v-if="isImage(file.filename)" class="fa fa-file-image-o fa-2x"></i>
                            <i v-else :class="getFileIcon(file.filename)"></i>
                            <a class="mleft-5" href="#" v-if="isImage(file.filename)" @click.prevent="showModal(file)">{{ file.filename }}</a>
                            <a class="mleft-5" :href="getDownloadUrl(file)" v-else>{{ file.filename }}</a>
                        </td>
                        <td>
                            <a href="#" v-if="isImage(file.filename)" @click.prevent="showModal(file)">
                                <img :alt="file.filename" :src="getImageUrl(file)" class="img-thumbnail" style="max-width: 150px; max-height: 150px;" />
                            </a>
                        </td>
                        <td>
                            <button class="btn btn-danger btn-sm" @click="deleteFile(file)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="modal fade" id="imageModal" tabindex="-1" role="dialog" aria-labelledby="imageModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-lg" v-if="currentFile">
                    <div class="modal-header">
                        <h5 class="modal-title" id="imageModalLabel">{{ currentFile.filename }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body text-center">
                        <img :alt="currentFile.filename" :src="getImageUrl(currentFile)" class="img-fluid" style="max-width:100%;"/>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import Dropzone from "dropzone";
import axios from "axios";
import {handleAjaxError, showSuccess} from "@/utils";

Dropzone.autoDiscover = false;


export default {
    name: "ImpCampanhasFicheiros",

    data() {
        return {
            indexUrl: jsRoutes.controllers.ImpCampanhasController.index().url,
            processing: false,
            files: [],
            currentFile: null,
            filterText: "",
        }
    },

    computed: {
        filteredFiles() {
            return this.files.filter((file) =>
                file.filename.toLowerCase().includes(this.filterText.toLowerCase())
            );
        },
    },

    mounted() {
        this.setupDropzone();
        this.loadFicheiros();
    },

    methods: {
        isImage(filename) {
            const extension = filename.split('.').pop().toLowerCase();
            return ['jpg', 'jpeg', 'png', 'gif'].includes(extension);
        },
        getFileIcon(filename) {
            const extension = filename.split('.').pop().toLowerCase();
            switch (extension) {
                case 'pdf':
                    return 'fa fa-file-pdf-o fa-2x';
                case 'doc':
                case 'docx':
                    return 'fa fa-file-word-o fa-2x';
                default:
                    return 'fa fa-file-o fa-2x';
            }
        },
        setupDropzone() {
            const self = this;
            this.dropzone = new Dropzone(this.$refs.dropzonearea, {
                url: jsRoutes.controllers.ImpCampanhasController.uploadFicheiro().url,
                paramName: "file", // The name that will be used to transfer the file
                maxFilesize: 50, // MB
                autoProcessQueue: true,
                uploadMultiple: false,
                addRemoveLinks: false,
                //acceptedFiles: ".xls, .xlsx",
                error: function (file, errorMessage) {//if the error was due to the XMLHttpRequest the xhr object as third
                    // eslint-disable-next-line
                    showError("Erro", errorMessage);
                    // self.uploadSuccess = false;
                    this.removeAllFiles(true);
                    self.dropzone.processQueue();
                },

                success: function (file, response) {
                    console.debug("#### success ", JSON.stringify(response));
                    // eslint-disable-next-line
                    //self.resultLog = response;
                    // self.uploadSuccess = true;
                    // self.files.push(response);
                    self.dropzone.processQueue();
                    self.files.push(response);
                    this.removeFile(file);
                },

            });
        },
        loadFicheiros() {
            const self = this;
            axios.get(jsRoutes.controllers.ImpCampanhasController.loadListaFicheiros().url)
                .then(response => {
                    self.files = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        deleteFile(file) {
            const self = this;
            const msg = "Tem a certeza que deseja apagar o ficheiro " + file.filename + "?";
            // eslint-disable-next-line
            showCustomDialog("Confirmação", msg, "Apagar", "btn-danger", function () {
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ImpCampanhasController.deleteFicheiro(file.id).url;
                axios.delete(url).then(() => {
                    // eslint-disable-next-line
                    showSuccess("Sucesso", "Ficheiro apagado");
                    self.loadFicheiros();
                }, function (error) {
                    handleAjaxError(error, "Erro ao apagar acessório");
                });
            });
        },
        showModal(file) {
            this.currentFile = file;
            $('#imageModal').modal('show');
        },
        getImageUrl(file) {
            return jsRoutes.controllers.ImpCampanhasController.downloadFicheiro(file.id).url;
        },
        getDownloadUrl(file) {
            return jsRoutes.controllers.ImpCampanhasController.downloadFicheiro(file.id).url;
        },
    }

}
</script>

<style scoped>

</style>
