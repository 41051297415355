<template>
    <div class="row">
        <div id="impAddPecas" class="col-sm-12">

            <div class="panel panel-default">

                <div class="panel-heading">
                    <h1>Criar Notificação</h1>
                </div>

                <div class="panel-body">
                    <div class="row">
                        <div class="col-sm-12">

                            <form novalidate>

                                <div class="form-group" :class="{'has-error' : $v.data.titulo.$error}">
                                    <label>Título *</label>
                                    <input type="text" class="form-control" v-model="data.titulo"/>
                                    <span class="help-block"
                                          v-show="!$v.data.titulo.required && $v.data.titulo.$error">
                                        Campo obrigatório
                                    </span>
                                    <span class="help-block"
                                          v-show="!$v.data.titulo.maxLength && $v.data.titulo.$error">
                                        O campo tem de ter menos de {{ $v.data.titulo.$params.maxLength.max }} caracteres
                                    </span>
                                </div>

                                <div class="form-group" :class="{'has-error' : $v.data.texto.$error}">
                                    <label>Texto *</label>
                                    <input type="hidden" class="form-control" v-model="data.texto"/>
                                    <vue-editor v-model="data.texto" :editorToolbar="customToolbar"/>
                                    <span class="help-block"
                                          v-show="!$v.data.texto.required && $v.data.texto.$error">
                                        Campo obrigatório
                                    </span>
                                </div>

                                <div class="form-group" :class="{'has-error' : $v.data.profileIds.$error}">
                                    <label>Perfil *</label>
                                    <b-select :value.sync="data.profileIds"
                                              :items="profiles"
                                              multiple
                                              class="form-control"></b-select>
                                    <span class="help-block"
                                          v-show="!$v.data.profileIds.required && $v.data.profileIds.$error">
                                        Campo obrigatório
                                    </span>
                                </div>

                                <div class="form-group">
                                    <label>Ficheiro</label>
                                    <div class="dropzone" ref="dropzonearea">
                                        <div class="dz-message">
                                            <h3>Arrastar ficheiros para carregar</h3><br>
                                            <span class="note">(Ou clicar com o rato para selecionar ficheiros)</span>
                                        </div>
                                    </div>
                                </div>

                            </form>


                            <div class="pull-right">
                                <router-link :to="{name: 'ImpNotificacoesHome'}">
                                    Cancelar
                                </router-link>

                                <v-button type="button" class="btn btn-primary"
                                          :loading="loading"
                                          @click="criarNotificacao">
                                    Criar Notificação
                                </v-button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {maxLength, required} from "vuelidate/lib/validators";
import Dropzone from "dropzone";
import {VueEditor} from "vue2-editor";

import BSelect from "@/components/BSelect.vue";
import VButton from "@/components/VButton.vue";
import {handleAjaxError, showSuccess} from "@/utils";

Dropzone.autoDiscover = false;


export default {
    name: "ImpNotificacoesCreate",

    components: {VButton, BSelect, VueEditor},

    data() {
        return {
            loading: false,
            data: {
                id: null,
                titulo: '',
                texto: '',
                profileIds: [],
                filename: '',
                sysfilename: '',
            },
            profiles: [],
            customToolbar: [
                ["bold", "italic", "underline"],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}],
                [{'indent': '-1'}, {'indent': '+1'}],
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                ['clean']
            ]
        }
    },

    mounted() {
        this.loadProfiles();
        this.setupDropzone();
    },

    validations: {
        data: {
            titulo: {
                required,
                maxLength: maxLength(150),
            },
            texto: {
                required,
            },
            profileIds: {
                required,
            },
        }
    },

    methods: {

        criarNotificacao() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                // console.error('data invalid');
                return;
            }

            const files = this.dropzone.files;
            const formData = new FormData();
            if (files[0]) {
                formData.append("file", files[0]);
            }
            formData.append("data", JSON.stringify(this.data));

            this.loading = true;
            // eslint-disable-next-line
            const url = jsRoutes.controllers.ImpNotificacoesController.criarNotificacao().url;

            axios.post(url, formData)
                .then((/*response*/) => {
                    // console.debug(response);
                    // eslint-disable-next-line
                    showSuccess('Sucesso', 'Notificação criada!');
                    this.$router.push({name: 'ImpNotificacoesHome'});
                })
                .catch(error => {
                    // console.error(error);
                    handleAjaxError(error, 'Erro!');
                })
                .finally(() => {
                    this.loading = false;
                });

        },

        loadProfiles() {
            // eslint-disable-next-line
            const url = jsRoutes.controllers.ImpNotificacoesController.loadProfiles().url;

            axios.get(url)
                .then(response => {
                    // console.debug(response);
                    this.profiles = response.data;
                })
                .catch(error => {
                    console.error(error);
                })
        },

        setupDropzone() {
            const self = this;
            this.dropzone = new Dropzone(this.$refs.dropzonearea, {
                url: "url", //dropzone.js:434 Uncaught Error: No URL provided
                paramName: "file", // The name that will be used to transfer the file
                maxFilesize: 50, // MB
                autoProcessQueue: false,
                uploadMultiple: true,
                addRemoveLinks: true,
                maxFiles: 1,
                //acceptedFiles: ".jpg, .jpeg, .png, .bmp, .tif, .tiff",
                error: function (file, errorMessage) {//if the error was due to the XMLHttpRequest the xhr object as third
                    // eslint-disable-next-line
                    showError("Erro", errorMessage);
                    // self.uploadSuccess = false;
                    this.removeFile(file);
                    // this.removeAllFiles(true);
                    // self.dropzone.processQueue();
                },

                success: function (file/*, response*/) {
                    // console.log("#### success");
                    // self.uploadSuccess = true;
                    // self.files.push(response);
                    self.dropzone.processQueue();
                    this.removeFile(file);
                },
                /**
                 * If `addRemoveLinks` is true, the text to be used to remove a file.
                 */
                dictRemoveFile: "Remover ficheiro",
                /**
                 * Displayed if `maxFiles` is st and exceeded.
                 * The string `{{maxFiles}}` will be replaced by the configuration value.
                 */
                dictMaxFilesExceeded: "Apenas um ficheiro permitido!",
            });
            // this.dropzoneSetup = true;
        },
    }

}
</script>

<style scoped>

</style>
