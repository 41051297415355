<template>
    <div class="col-sm-12">
        <div class="panel panel-default">
            <div class="panel-heading">
                <h3>
                    Descodificador Chassis
                    <a :href="indexUrl" class="btn btn-primary">
                        <span class="glyphicon glyphicon-arrow-left"></span>
                        Voltar
                    </a>
                </h3>
            </div>
            <div class="panel-body">
                <p class="bg-info" style="padding:15px;">
                    <b>NOTA: O excel a carregar deve ter o mesmo formato que este
                        <a :href="exampleUrl">exemplo</a>
                    </b>
                </p>
                <div class="col-md-12 " id="dropzonearea" ref="dropzonearea">
                    <div class="dz-message">
                        <h3>Arrastar ficheiros para carregar</h3><br>
                        <span class="note">(Ou clicar com o rato para selecionar ficheiros)</span>
                    </div>
                    <select id="filesData" name="files" multiple="multiple" hidden>
                    </select>
                </div>
            </div>
            <ul class="list-group">
                <li class="list-group-item">
                    <h4>Resultados</h4>
                </li>
                <li class="list-group-item" v-if="processing">
                    <h3>&nbsp;&nbsp;&nbsp;<span class="fa fa-spinner fa-2x fa-spin"></span> A processar...</h3>
                </li>

                <span v-html="resultLog"></span>

                <!--                {{{resultLog}}}-->

            </ul>
        </div>
    </div>
</template>


<script>
import Dropzone from "dropzone";

Dropzone.autoDiscover = false;


export default {
    name: "DescodificadorChassis",

    data() {
        return {
            indexUrl: jsRoutes.controllers.ImpCampanhasController.index().url,
            exampleUrl: '/assets/campanhas/Descodificador-modelol.xlsx',
            processing: false,
            resultLog: '',
        }
    },

    mounted() {
        this.setupDropzone();
    },

    methods: {
        setupDropzone() {
            const self = this;
            this.dropzone = new Dropzone(this.$refs.dropzonearea, {
                url: jsRoutes.controllers.ImpCampanhasController.parseDescodificadorChassisFile().url,
                paramName: "file", // The name that will be used to transfer the file
                maxFilesize: 50, // MB
                autoProcessQueue: true,
                uploadMultiple: false,
                addRemoveLinks: false,
                acceptedFiles: ".xls, .xlsx",
                error: function (file, errorMessage) {//if the error was due to the XMLHttpRequest the xhr object as third
                    // eslint-disable-next-line
                    showError("Erro", errorMessage);
                    // self.uploadSuccess = false;
                    this.removeAllFiles(true);
                    self.dropzone.processQueue();
                },

                success: function (file, response) {
                    // console.debug("#### success ", response);
                    // eslint-disable-next-line
                    showSuccess("Sucesso", "Ficheiro carregado");
                    self.resultLog = response;
                    // self.uploadSuccess = true;
                    // self.files.push(response);
                    self.dropzone.processQueue();
                    this.removeFile(file);
                },

            });
        },
    }

}
</script>

<style scoped>

</style>
