<template>
    <div>
        <div class="col-sm-12">
            <h4>
                <router-link :to="{name :'ImpAcessoriosHome'}">
                    <i class="fa fa-arrow-left"></i> Voltar
                </router-link>
            </h4>
            <h3>
                <router-link :to="{name :'ImpAcessoriosHome'}">
                    {{ modeloNome }}
                </router-link>
            </h3>
            <hr/>
            <h4 style="margin-top:0;">
                Acessórios
                <button v-if="permissoesEscrita"
                        class="btn btn-primary"
                        v-on:click="showCriar = true">
                    <i class="fa fa-cog"></i> Criar Acessório
                </button>
                &nbsp;&nbsp;
                <button v-if="permissoesEscrita"
                        class="btn btn-primary"
                        v-on:click="showCriarCategoria = true">
                    <i class="fa fa-cog"></i> Criar Categoria
                </button>
            </h4>
            <div v-if="permissoesEscrita" class="alert alert-info">Arrastar imagens para reordenar</div>
        </div>

        <div class="row">
            <div style="margin-top:10px;"></div>
            <div class="col-sm-2">
                <div class="list-group">
                    <div class="list-group-item">
                        <strong class="text-center">Selecionar a Categoria</strong>
                    </div>
                    <div class="list-group-item"
                         style="cursor: pointer"
                         @click="setCategory(cat.id)"
                         :class="{'list-group-item-info': filterCategory === cat.id}"
                         v-for="cat in categorias" :key="cat.id">
                        {{ cat.nome }}
                    </div>
                </div>
            </div>
            <div class="col-sm-10">
                <div v-if="loading">
                    <i class="fa fa-2x fa-spinner fa-spin"></i>
                </div>
                <div class="row" v-if="!loading">
                    <draggable v-model="acessorios" :disabled="!permissoesEscrita" group="acessorios"
                               @change="updateOrdem">
                        <div class="col-md-3 col-sm-6 col-xs-12" style="margin-bottom: 30px;"
                             v-for="acessorio in acessorios" :key="acessorio.id">
                            <div class="model-container">
                                <div class="img-container">
                                    <img class="model-img" :src="acessorioFicheiroUrl(acessorio.ficheiroId)"
                                         @click="openAcessorio(acessorio.id)">
                                </div>
                                <div>
                                    <div style="font-weight: bold;text-align: center;">{{ acessorio.nome }}</div>
                                    <div style="display:flex;justify-content: space-between;">
                                        <button class="btn btn-sm btn-default" @click="openEdit(acessorio)"><i
                                            class="fa fa-edit"></i></button>
                                        <button class="btn btn-sm btn-danger" @click="deleteAcessorio(acessorio)"><i
                                            class="fa fa-trash-o"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </draggable>
                </div>
            </div>
        </div>

        <criar-acessorio-modal :show.sync="showCriar" :modelo-id="modeloId"
                               :categorias="categoriasSelect"
                               @refresh="loadAcessorios"></criar-acessorio-modal>
        <editar-acessorio-modal :show.sync="showEditar" :acessorio="acessorioEdit"
                                :categorias="categoriasSelect"
                                @refresh="loadAcessorios"></editar-acessorio-modal>
        <criar-categoria-modal :show.sync="showCriarCategoria"
                               @refresh="loadCategorias"></criar-categoria-modal>
    </div>

</template>
<style lang="css" scoped>
.model-container {
    margin-right: 0;
    margin-left: 0;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px 4px 0 0;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.img-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.model-img {
    max-width: 100%;
    max-height: 188px;
    vertical-align: middle;
}

.model-img:hover {
    cursor: pointer;
}
</style>
<script>
// @ is an alias to /src
import axios from 'axios'
import draggable from 'vuedraggable'
import {handleAjaxError, showSuccess} from "@/utils";

import EditarAcessorioModal from "@/acessoriosimp/modal/EditarAcessorioModal";
import CriarAcessorioModal from "@/acessoriosimp/modal/CriarAcessorioModal";
import CriarCategoriaModal from "@/acessoriosimp/modal/CriarCategoriaModal.vue";


export default {
    components: {
        CriarCategoriaModal,
        EditarAcessorioModal,
        CriarAcessorioModal,
        draggable,
    },
    props: ["modeloId"],
    data() {
        return {
            showCriar: false,
            showCriarCategoria: false,
            loading: false,

            permissoesEscrita: false,

            modeloNome: "",
            acessorios: [],

            showEditar: false,
            acessorioEdit: {
                id: null,
                nome: null,
            },

            categorias: [],
            filterCategory: 0
        }
    },
    mounted() {
        // eslint-disable-next-line
        this.permissoesEscrita = permEsc;
        // this.permissoesEscrita = true;
        this.loadAcessorios();
        this.loadCategorias();
    },
    computed: {
        categoriasSelect() {
            const list = this.categorias.map(value => ({id: value.id, text: value.nome}));
            list.unshift({id: null, text: '-- Sem categoria --'})
            return list;
        }
    },
    methods: {

        setCategory(id) {
            if (id === this.filterCategory) {
                this.filterCategory = 0;
            } else {
                this.filterCategory = id;
            }
            this.loadAcessorios();
        },

        loadCategorias() {

            // eslint-disable-next-line
            const url = jsRoutes.controllers.ImpAcessoriosController.loadCategoriasAcessorios().url;
            axios.get(url)
                .then(response => {
                    // console.debug(response)
                    this.categorias = response.data;
                })
                .catch(error => {
                    console.error(error)
                    handleAjaxError('Erro', error);
                })

        },

        openAcessorio(id) {
            this.$router.push({name: 'ImpDetalheAcessorio', params: {id: id}})
        },
        acessorioFicheiroUrl(id) {
            // eslint-disable-next-line
            return jsRoutes.controllers.ImpAcessoriosController.acessorioFicheiro(id).url;
        },
        openEdit(acessorio) {
            this.acessorioEdit = acessorio;
            this.showEditar = true;
        },
        deleteAcessorio(acessorio) {
            const self = this;
            const msg = "Tem a certeza que deseja apagar o acessório?";
            // eslint-disable-next-line
            showCustomDialog("Confirmação", msg, "Apagar", "btn-danger", function () {
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ImpAcessoriosController.apagarAcessorio(acessorio.id).url;
                axios.get(url).then(() => {
                    // eslint-disable-next-line
                    showSuccess("Sucesso", "Acessório apagado");
                    self.loadAcessorios();
                }, function (error) {
                    handleAjaxError(error, "Erro ao apagar acessório");
                });
            });
        },
        loadAcessorios() {
            const self = this;
            this.loading = true;

            // console.debug('loadAcessorios - filter cat: ', this.filterCategory);

            // eslint-disable-next-line
            const url = jsRoutes.controllers.ImpAcessoriosController.loadAcessorios(this.modeloId, this.filterCategory).url;

            // GET request
            axios.get(url).then(function (response) {
                self.loading = false;
                // set data
                self.modeloNome = response.data.modeloNome;
                self.acessorios = response.data.acessorios;
            }).catch(function (error) {
                self.loading = false;
                // handle error
                // eslint-disable-next-line
                handleAjaxError(error, "Erro ao carregar lista de acessórios");
            });
        },
        updateOrdem() {
            // eslint-disable-next-line
            const url = jsRoutes.controllers.ImpAcessoriosController.editarAcessoriosOrdem().url;

            let dataObj = {
                ids: []
            };
            this.acessorios.forEach(a => {
                dataObj.ids.push(a.id);
            });

            axios.post(url, dataObj).then(function () {
                // eslint-disable-next-line
                showSuccess('Sucesso', 'Ordem actualizada');
            }, function (error) {
                // eslint-disable-next-line
                handleAjaxError(error, 'Ocorreu um erro ao actualizar ordem de acessórios');
            });
        }
    }
}
</script>
