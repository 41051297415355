<template>
    <div class="modal" tabindex="-1" role="dialog" ref="editFileModal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button>
                    <h5 class="modal-title">Editar Ficheiro </h5>
                </div>

                <div class="modal-body">
                    <div class="form-group"
                         :class="{'has-error' : $v.fileData.filename.$error}">
                        <label>Nome *</label>
                        <input type="text"
                               class="form-control" v-model="fileData.filename">
                        <span class="help-block"
                              v-show="!$v.fileData.filename.required && $v.fileData.filename.$error">
                            Campo obrigatório
                        </span>
                        <span class="help-block"
                              v-show="!$v.fileData.filename.maxLength && $v.fileData.filename.$error">
                            O campo tem de ter menos de {{ $v.fileData.filename.$params.maxLength.max }} caracteres
                        </span>
                    </div>
                    <div class="form-group"
                         :class="{'has-error' : $v.fileData.descricao.$error}">
                        <label>Descrição</label>
                        <textarea class="form-control"

                                  v-model="fileData.descricao"></textarea>
                        <span class="help-block"
                              v-show="!$v.fileData.descricao.maxLength && $v.fileData.descricao.$error">
                            O campo tem de ter menos de {{ $v.fileData.descricao.$params.maxLength.max }} caracteres
                        </span>
                    </div>

                    <div class="form-group">
                        <label>Icons</label>
                        <b-select :value.sync="selectedIcons" :items="iconsList" multiple data-live-search="true"
                                  data-width="100%"/>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-default"
                            data-dismiss="modal">
                        Cancelar
                    </button>
                    <v-button type="button" class="btn btn-primary"
                              :loading="loading"
                              v-on:click.prevent="editar">
                        Guardar
                    </v-button>
                </div>

            </div>
        </div>
    </div>
</template>


<script>
import VButton from "@/components/VButton.vue";
import axios from "axios";

import {handleAjaxError, showSuccess} from "@/utils";
import {maxLength, required} from "vuelidate/lib/validators";
import BSelect from "@/components/BSelect.vue";


export default {
    name: "EditarFicheiroDataModal",
    components: {BSelect, VButton},
    props: ['show', 'file'],
    emit: ['update:show', 'refresh'],

    data() {
        return {
            loading: false,

            fileData: {},

            // https://fontawesome.com/v4/icons/
            iconsList: [
                {
                    "id": "fa-500px",
                    "text": "<i class=\"fa fa-2x fa-500px\" aria-hidden=\"true\"></i> 500px"
                },
                {
                    "id": "fa-adjust",
                    "text": "<i class=\"fa fa-2x fa-adjust\" aria-hidden=\"true\"></i> adjust"
                },
                {
                    "id": "fa-adn",
                    "text": "<i class=\"fa fa-2x fa-adn\" aria-hidden=\"true\"></i> adn"
                },
                {
                    "id": "fa-align-center",
                    "text": "<i class=\"fa fa-2x fa-align-center\" aria-hidden=\"true\"></i> align center"
                },
                {
                    "id": "fa-align-justify",
                    "text": "<i class=\"fa fa-2x fa-align-justify\" aria-hidden=\"true\"></i> align justify"
                },
                {
                    "id": "fa-align-left",
                    "text": "<i class=\"fa fa-2x fa-align-left\" aria-hidden=\"true\"></i> align left"
                },
                {
                    "id": "fa-align-right",
                    "text": "<i class=\"fa fa-2x fa-align-right\" aria-hidden=\"true\"></i> align right"
                },
                {
                    "id": "fa-amazon",
                    "text": "<i class=\"fa fa-2x fa-amazon\" aria-hidden=\"true\"></i> amazon"
                },
                {
                    "id": "fa-ambulance",
                    "text": "<i class=\"fa fa-2x fa-ambulance\" aria-hidden=\"true\"></i> ambulance"
                },
                {
                    "id": "fa-american-sign-language-interpreting",
                    "text": "<i class=\"fa fa-2x fa-american-sign-language-interpreting\" aria-hidden=\"true\"></i> american sign language interpreting"
                },
                {
                    "id": "fa-anchor",
                    "text": "<i class=\"fa fa-2x fa-anchor\" aria-hidden=\"true\"></i> anchor"
                },
                {
                    "id": "fa-android",
                    "text": "<i class=\"fa fa-2x fa-android\" aria-hidden=\"true\"></i> android"
                },
                {
                    "id": "fa-angellist",
                    "text": "<i class=\"fa fa-2x fa-angellist\" aria-hidden=\"true\"></i> angellist"
                },
                {
                    "id": "fa-angle-double-down",
                    "text": "<i class=\"fa fa-2x fa-angle-double-down\" aria-hidden=\"true\"></i> angle double down"
                },
                {
                    "id": "fa-angle-double-left",
                    "text": "<i class=\"fa fa-2x fa-angle-double-left\" aria-hidden=\"true\"></i> angle double left"
                },
                {
                    "id": "fa-angle-double-right",
                    "text": "<i class=\"fa fa-2x fa-angle-double-right\" aria-hidden=\"true\"></i> angle double right"
                },
                {
                    "id": "fa-angle-double-up",
                    "text": "<i class=\"fa fa-2x fa-angle-double-up\" aria-hidden=\"true\"></i> angle double up"
                },
                {
                    "id": "fa-angle-down",
                    "text": "<i class=\"fa fa-2x fa-angle-down\" aria-hidden=\"true\"></i> angle down"
                },
                {
                    "id": "fa-angle-left",
                    "text": "<i class=\"fa fa-2x fa-angle-left\" aria-hidden=\"true\"></i> angle left"
                },
                {
                    "id": "fa-angle-right",
                    "text": "<i class=\"fa fa-2x fa-angle-right\" aria-hidden=\"true\"></i> angle right"
                },
                {
                    "id": "fa-angle-up",
                    "text": "<i class=\"fa fa-2x fa-angle-up\" aria-hidden=\"true\"></i> angle up"
                },
                {
                    "id": "fa-apple",
                    "text": "<i class=\"fa fa-2x fa-apple\" aria-hidden=\"true\"></i> apple"
                },
                {
                    "id": "fa-archive",
                    "text": "<i class=\"fa fa-2x fa-archive\" aria-hidden=\"true\"></i> archive"
                },
                {
                    "id": "fa-area-chart",
                    "text": "<i class=\"fa fa-2x fa-area-chart\" aria-hidden=\"true\"></i> area chart"
                },
                {
                    "id": "fa-arrow-circle-down",
                    "text": "<i class=\"fa fa-2x fa-arrow-circle-down\" aria-hidden=\"true\"></i> arrow circle down"
                },
                {
                    "id": "fa-arrow-circle-left",
                    "text": "<i class=\"fa fa-2x fa-arrow-circle-left\" aria-hidden=\"true\"></i> arrow circle left"
                },
                {
                    "id": "fa-arrow-circle-o-down",
                    "text": "<i class=\"fa fa-2x fa-arrow-circle-o-down\" aria-hidden=\"true\"></i> arrow circle o down"
                },
                {
                    "id": "fa-arrow-circle-o-left",
                    "text": "<i class=\"fa fa-2x fa-arrow-circle-o-left\" aria-hidden=\"true\"></i> arrow circle o left"
                },
                {
                    "id": "fa-arrow-circle-o-right",
                    "text": "<i class=\"fa fa-2x fa-arrow-circle-o-right\" aria-hidden=\"true\"></i> arrow circle o right"
                },
                {
                    "id": "fa-arrow-circle-o-up",
                    "text": "<i class=\"fa fa-2x fa-arrow-circle-o-up\" aria-hidden=\"true\"></i> arrow circle o up"
                },
                {
                    "id": "fa-arrow-circle-right",
                    "text": "<i class=\"fa fa-2x fa-arrow-circle-right\" aria-hidden=\"true\"></i> arrow circle right"
                },
                {
                    "id": "fa-arrow-circle-up",
                    "text": "<i class=\"fa fa-2x fa-arrow-circle-up\" aria-hidden=\"true\"></i> arrow circle up"
                },
                {
                    "id": "fa-arrow-down",
                    "text": "<i class=\"fa fa-2x fa-arrow-down\" aria-hidden=\"true\"></i> arrow down"
                },
                {
                    "id": "fa-arrow-left",
                    "text": "<i class=\"fa fa-2x fa-arrow-left\" aria-hidden=\"true\"></i> arrow left"
                },
                {
                    "id": "fa-arrow-right",
                    "text": "<i class=\"fa fa-2x fa-arrow-right\" aria-hidden=\"true\"></i> arrow right"
                },
                {
                    "id": "fa-arrow-up",
                    "text": "<i class=\"fa fa-2x fa-arrow-up\" aria-hidden=\"true\"></i> arrow up"
                },
                {
                    "id": "fa-arrows",
                    "text": "<i class=\"fa fa-2x fa-arrows\" aria-hidden=\"true\"></i> arrows"
                },
                {
                    "id": "fa-arrows-alt",
                    "text": "<i class=\"fa fa-2x fa-arrows-alt\" aria-hidden=\"true\"></i> arrows alt"
                },
                {
                    "id": "fa-arrows-h",
                    "text": "<i class=\"fa fa-2x fa-arrows-h\" aria-hidden=\"true\"></i> arrows h"
                },
                {
                    "id": "fa-arrows-v",
                    "text": "<i class=\"fa fa-2x fa-arrows-v\" aria-hidden=\"true\"></i> arrows v"
                },
                {
                    "id": "fa-asl-interpreting",
                    "text": "<i class=\"fa fa-2x fa-asl-interpreting\" aria-hidden=\"true\"></i> asl interpreting"
                },
                {
                    "id": "fa-assistive-listening-systems",
                    "text": "<i class=\"fa fa-2x fa-assistive-listening-systems\" aria-hidden=\"true\"></i> assistive listening systems"
                },
                {
                    "id": "fa-asterisk",
                    "text": "<i class=\"fa fa-2x fa-asterisk\" aria-hidden=\"true\"></i> asterisk"
                },
                {
                    "id": "fa-at",
                    "text": "<i class=\"fa fa-2x fa-at\" aria-hidden=\"true\"></i> at"
                },
                {
                    "id": "fa-audio-description",
                    "text": "<i class=\"fa fa-2x fa-audio-description\" aria-hidden=\"true\"></i> audio description"
                },
                {
                    "id": "fa-automobile",
                    "text": "<i class=\"fa fa-2x fa-automobile\" aria-hidden=\"true\"></i> automobile"
                },
                {
                    "id": "fa-backward",
                    "text": "<i class=\"fa fa-2x fa-backward\" aria-hidden=\"true\"></i> backward"
                },
                {
                    "id": "fa-balance-scale",
                    "text": "<i class=\"fa fa-2x fa-balance-scale\" aria-hidden=\"true\"></i> balance scale"
                },
                {
                    "id": "fa-ban",
                    "text": "<i class=\"fa fa-2x fa-ban\" aria-hidden=\"true\"></i> ban"
                },
                {
                    "id": "fa-bandcamp",
                    "text": "<i class=\"fa fa-2x fa-bandcamp\" aria-hidden=\"true\"></i> bandcamp"
                },
                {
                    "id": "fa-bank",
                    "text": "<i class=\"fa fa-2x fa-bank\" aria-hidden=\"true\"></i> bank"
                },
                {
                    "id": "fa-bar-chart",
                    "text": "<i class=\"fa fa-2x fa-bar-chart\" aria-hidden=\"true\"></i> bar chart"
                },
                {
                    "id": "fa-bar-chart-o",
                    "text": "<i class=\"fa fa-2x fa-bar-chart-o\" aria-hidden=\"true\"></i> bar chart o"
                },
                {
                    "id": "fa-barcode",
                    "text": "<i class=\"fa fa-2x fa-barcode\" aria-hidden=\"true\"></i> barcode"
                },
                {
                    "id": "fa-bars",
                    "text": "<i class=\"fa fa-2x fa-bars\" aria-hidden=\"true\"></i> bars"
                },
                {
                    "id": "fa-bath",
                    "text": "<i class=\"fa fa-2x fa-bath\" aria-hidden=\"true\"></i> bath"
                },
                {
                    "id": "fa-bathtub",
                    "text": "<i class=\"fa fa-2x fa-bathtub\" aria-hidden=\"true\"></i> bathtub"
                },
                {
                    "id": "fa-battery",
                    "text": "<i class=\"fa fa-2x fa-battery\" aria-hidden=\"true\"></i> battery"
                },
                {
                    "id": "fa-battery-0",
                    "text": "<i class=\"fa fa-2x fa-battery-0\" aria-hidden=\"true\"></i> battery 0"
                },
                {
                    "id": "fa-battery-1",
                    "text": "<i class=\"fa fa-2x fa-battery-1\" aria-hidden=\"true\"></i> battery 1"
                },
                {
                    "id": "fa-battery-2",
                    "text": "<i class=\"fa fa-2x fa-battery-2\" aria-hidden=\"true\"></i> battery 2"
                },
                {
                    "id": "fa-battery-3",
                    "text": "<i class=\"fa fa-2x fa-battery-3\" aria-hidden=\"true\"></i> battery 3"
                },
                {
                    "id": "fa-battery-4",
                    "text": "<i class=\"fa fa-2x fa-battery-4\" aria-hidden=\"true\"></i> battery 4"
                },
                {
                    "id": "fa-battery-empty",
                    "text": "<i class=\"fa fa-2x fa-battery-empty\" aria-hidden=\"true\"></i> battery empty"
                },
                {
                    "id": "fa-battery-full",
                    "text": "<i class=\"fa fa-2x fa-battery-full\" aria-hidden=\"true\"></i> battery full"
                },
                {
                    "id": "fa-battery-half",
                    "text": "<i class=\"fa fa-2x fa-battery-half\" aria-hidden=\"true\"></i> battery half"
                },
                {
                    "id": "fa-battery-quarter",
                    "text": "<i class=\"fa fa-2x fa-battery-quarter\" aria-hidden=\"true\"></i> battery quarter"
                },
                {
                    "id": "fa-battery-three-quarters",
                    "text": "<i class=\"fa fa-2x fa-battery-three-quarters\" aria-hidden=\"true\"></i> battery three quarters"
                },
                {
                    "id": "fa-bed",
                    "text": "<i class=\"fa fa-2x fa-bed\" aria-hidden=\"true\"></i> bed"
                },
                {
                    "id": "fa-beer",
                    "text": "<i class=\"fa fa-2x fa-beer\" aria-hidden=\"true\"></i> beer"
                },
                {
                    "id": "fa-behance",
                    "text": "<i class=\"fa fa-2x fa-behance\" aria-hidden=\"true\"></i> behance"
                },
                {
                    "id": "fa-behance-square",
                    "text": "<i class=\"fa fa-2x fa-behance-square\" aria-hidden=\"true\"></i> behance square"
                },
                {
                    "id": "fa-bell",
                    "text": "<i class=\"fa fa-2x fa-bell\" aria-hidden=\"true\"></i> bell"
                },
                {
                    "id": "fa-bell-o",
                    "text": "<i class=\"fa fa-2x fa-bell-o\" aria-hidden=\"true\"></i> bell o"
                },
                {
                    "id": "fa-bell-slash",
                    "text": "<i class=\"fa fa-2x fa-bell-slash\" aria-hidden=\"true\"></i> bell slash"
                },
                {
                    "id": "fa-bell-slash-o",
                    "text": "<i class=\"fa fa-2x fa-bell-slash-o\" aria-hidden=\"true\"></i> bell slash o"
                },
                {
                    "id": "fa-bicycle",
                    "text": "<i class=\"fa fa-2x fa-bicycle\" aria-hidden=\"true\"></i> bicycle"
                },
                {
                    "id": "fa-binoculars",
                    "text": "<i class=\"fa fa-2x fa-binoculars\" aria-hidden=\"true\"></i> binoculars"
                },
                {
                    "id": "fa-birthday-cake",
                    "text": "<i class=\"fa fa-2x fa-birthday-cake\" aria-hidden=\"true\"></i> birthday cake"
                },
                {
                    "id": "fa-bitbucket",
                    "text": "<i class=\"fa fa-2x fa-bitbucket\" aria-hidden=\"true\"></i> bitbucket"
                },
                {
                    "id": "fa-bitbucket-square",
                    "text": "<i class=\"fa fa-2x fa-bitbucket-square\" aria-hidden=\"true\"></i> bitbucket square"
                },
                {
                    "id": "fa-bitcoin",
                    "text": "<i class=\"fa fa-2x fa-bitcoin\" aria-hidden=\"true\"></i> bitcoin"
                },
                {
                    "id": "fa-black-tie",
                    "text": "<i class=\"fa fa-2x fa-black-tie\" aria-hidden=\"true\"></i> black tie"
                },
                {
                    "id": "fa-blind",
                    "text": "<i class=\"fa fa-2x fa-blind\" aria-hidden=\"true\"></i> blind"
                },
                {
                    "id": "fa-bluetooth",
                    "text": "<i class=\"fa fa-2x fa-bluetooth\" aria-hidden=\"true\"></i> bluetooth"
                },
                {
                    "id": "fa-bluetooth-b",
                    "text": "<i class=\"fa fa-2x fa-bluetooth-b\" aria-hidden=\"true\"></i> bluetooth b"
                },
                {
                    "id": "fa-bold",
                    "text": "<i class=\"fa fa-2x fa-bold\" aria-hidden=\"true\"></i> bold"
                },
                {
                    "id": "fa-bolt",
                    "text": "<i class=\"fa fa-2x fa-bolt\" aria-hidden=\"true\"></i> bolt"
                },
                {
                    "id": "fa-bomb",
                    "text": "<i class=\"fa fa-2x fa-bomb\" aria-hidden=\"true\"></i> bomb"
                },
                {
                    "id": "fa-book",
                    "text": "<i class=\"fa fa-2x fa-book\" aria-hidden=\"true\"></i> book"
                },
                {
                    "id": "fa-bookmark",
                    "text": "<i class=\"fa fa-2x fa-bookmark\" aria-hidden=\"true\"></i> bookmark"
                },
                {
                    "id": "fa-bookmark-o",
                    "text": "<i class=\"fa fa-2x fa-bookmark-o\" aria-hidden=\"true\"></i> bookmark o"
                },
                {
                    "id": "fa-braille",
                    "text": "<i class=\"fa fa-2x fa-braille\" aria-hidden=\"true\"></i> braille"
                },
                {
                    "id": "fa-briefcase",
                    "text": "<i class=\"fa fa-2x fa-briefcase\" aria-hidden=\"true\"></i> briefcase"
                },
                {
                    "id": "fa-btc",
                    "text": "<i class=\"fa fa-2x fa-btc\" aria-hidden=\"true\"></i> btc"
                },
                {
                    "id": "fa-bug",
                    "text": "<i class=\"fa fa-2x fa-bug\" aria-hidden=\"true\"></i> bug"
                },
                {
                    "id": "fa-building",
                    "text": "<i class=\"fa fa-2x fa-building\" aria-hidden=\"true\"></i> building"
                },
                {
                    "id": "fa-building-o",
                    "text": "<i class=\"fa fa-2x fa-building-o\" aria-hidden=\"true\"></i> building o"
                },
                {
                    "id": "fa-bullhorn",
                    "text": "<i class=\"fa fa-2x fa-bullhorn\" aria-hidden=\"true\"></i> bullhorn"
                },
                {
                    "id": "fa-bullseye",
                    "text": "<i class=\"fa fa-2x fa-bullseye\" aria-hidden=\"true\"></i> bullseye"
                },
                {
                    "id": "fa-bus",
                    "text": "<i class=\"fa fa-2x fa-bus\" aria-hidden=\"true\"></i> bus"
                },
                {
                    "id": "fa-buysellads",
                    "text": "<i class=\"fa fa-2x fa-buysellads\" aria-hidden=\"true\"></i> buysellads"
                },
                {
                    "id": "fa-cab",
                    "text": "<i class=\"fa fa-2x fa-cab\" aria-hidden=\"true\"></i> cab"
                },
                {
                    "id": "fa-calculator",
                    "text": "<i class=\"fa fa-2x fa-calculator\" aria-hidden=\"true\"></i> calculator"
                },
                {
                    "id": "fa-calendar",
                    "text": "<i class=\"fa fa-2x fa-calendar\" aria-hidden=\"true\"></i> calendar"
                },
                {
                    "id": "fa-calendar-check-o",
                    "text": "<i class=\"fa fa-2x fa-calendar-check-o\" aria-hidden=\"true\"></i> calendar check o"
                },
                {
                    "id": "fa-calendar-minus-o",
                    "text": "<i class=\"fa fa-2x fa-calendar-minus-o\" aria-hidden=\"true\"></i> calendar minus o"
                },
                {
                    "id": "fa-calendar-o",
                    "text": "<i class=\"fa fa-2x fa-calendar-o\" aria-hidden=\"true\"></i> calendar o"
                },
                {
                    "id": "fa-calendar-plus-o",
                    "text": "<i class=\"fa fa-2x fa-calendar-plus-o\" aria-hidden=\"true\"></i> calendar plus o"
                },
                {
                    "id": "fa-calendar-times-o",
                    "text": "<i class=\"fa fa-2x fa-calendar-times-o\" aria-hidden=\"true\"></i> calendar times o"
                },
                {
                    "id": "fa-camera",
                    "text": "<i class=\"fa fa-2x fa-camera\" aria-hidden=\"true\"></i> camera"
                },
                {
                    "id": "fa-camera-retro",
                    "text": "<i class=\"fa fa-2x fa-camera-retro\" aria-hidden=\"true\"></i> camera retro"
                },
                {
                    "id": "fa-car",
                    "text": "<i class=\"fa fa-2x fa-car\" aria-hidden=\"true\"></i> car"
                },
                {
                    "id": "fa-caret-down",
                    "text": "<i class=\"fa fa-2x fa-caret-down\" aria-hidden=\"true\"></i> caret down"
                },
                {
                    "id": "fa-caret-left",
                    "text": "<i class=\"fa fa-2x fa-caret-left\" aria-hidden=\"true\"></i> caret left"
                },
                {
                    "id": "fa-caret-right",
                    "text": "<i class=\"fa fa-2x fa-caret-right\" aria-hidden=\"true\"></i> caret right"
                },
                {
                    "id": "fa-caret-square-o-down",
                    "text": "<i class=\"fa fa-2x fa-caret-square-o-down\" aria-hidden=\"true\"></i> caret square o down"
                },
                {
                    "id": "fa-caret-square-o-left",
                    "text": "<i class=\"fa fa-2x fa-caret-square-o-left\" aria-hidden=\"true\"></i> caret square o left"
                },
                {
                    "id": "fa-caret-square-o-right",
                    "text": "<i class=\"fa fa-2x fa-caret-square-o-right\" aria-hidden=\"true\"></i> caret square o right"
                },
                {
                    "id": "fa-caret-square-o-up",
                    "text": "<i class=\"fa fa-2x fa-caret-square-o-up\" aria-hidden=\"true\"></i> caret square o up"
                },
                {
                    "id": "fa-caret-up",
                    "text": "<i class=\"fa fa-2x fa-caret-up\" aria-hidden=\"true\"></i> caret up"
                },
                {
                    "id": "fa-cart-arrow-down",
                    "text": "<i class=\"fa fa-2x fa-cart-arrow-down\" aria-hidden=\"true\"></i> cart arrow down"
                },
                {
                    "id": "fa-cart-plus",
                    "text": "<i class=\"fa fa-2x fa-cart-plus\" aria-hidden=\"true\"></i> cart plus"
                },
                {
                    "id": "fa-cc",
                    "text": "<i class=\"fa fa-2x fa-cc\" aria-hidden=\"true\"></i> cc"
                },
                {
                    "id": "fa-cc-amex",
                    "text": "<i class=\"fa fa-2x fa-cc-amex\" aria-hidden=\"true\"></i> cc amex"
                },
                {
                    "id": "fa-cc-diners-club",
                    "text": "<i class=\"fa fa-2x fa-cc-diners-club\" aria-hidden=\"true\"></i> cc diners club"
                },
                {
                    "id": "fa-cc-discover",
                    "text": "<i class=\"fa fa-2x fa-cc-discover\" aria-hidden=\"true\"></i> cc discover"
                },
                {
                    "id": "fa-cc-jcb",
                    "text": "<i class=\"fa fa-2x fa-cc-jcb\" aria-hidden=\"true\"></i> cc jcb"
                },
                {
                    "id": "fa-cc-mastercard",
                    "text": "<i class=\"fa fa-2x fa-cc-mastercard\" aria-hidden=\"true\"></i> cc mastercard"
                },
                {
                    "id": "fa-cc-paypal",
                    "text": "<i class=\"fa fa-2x fa-cc-paypal\" aria-hidden=\"true\"></i> cc paypal"
                },
                {
                    "id": "fa-cc-stripe",
                    "text": "<i class=\"fa fa-2x fa-cc-stripe\" aria-hidden=\"true\"></i> cc stripe"
                },
                {
                    "id": "fa-cc-visa",
                    "text": "<i class=\"fa fa-2x fa-cc-visa\" aria-hidden=\"true\"></i> cc visa"
                },
                {
                    "id": "fa-certificate",
                    "text": "<i class=\"fa fa-2x fa-certificate\" aria-hidden=\"true\"></i> certificate"
                },
                {
                    "id": "fa-chain",
                    "text": "<i class=\"fa fa-2x fa-chain\" aria-hidden=\"true\"></i> chain"
                },
                {
                    "id": "fa-chain-broken",
                    "text": "<i class=\"fa fa-2x fa-chain-broken\" aria-hidden=\"true\"></i> chain broken"
                },
                {
                    "id": "fa-check",
                    "text": "<i class=\"fa fa-2x fa-check\" aria-hidden=\"true\"></i> check"
                },
                {
                    "id": "fa-check-circle",
                    "text": "<i class=\"fa fa-2x fa-check-circle\" aria-hidden=\"true\"></i> check circle"
                },
                {
                    "id": "fa-check-circle-o",
                    "text": "<i class=\"fa fa-2x fa-check-circle-o\" aria-hidden=\"true\"></i> check circle o"
                },
                {
                    "id": "fa-check-square",
                    "text": "<i class=\"fa fa-2x fa-check-square\" aria-hidden=\"true\"></i> check square"
                },
                {
                    "id": "fa-check-square-o",
                    "text": "<i class=\"fa fa-2x fa-check-square-o\" aria-hidden=\"true\"></i> check square o"
                },
                {
                    "id": "fa-chevron-circle-down",
                    "text": "<i class=\"fa fa-2x fa-chevron-circle-down\" aria-hidden=\"true\"></i> chevron circle down"
                },
                {
                    "id": "fa-chevron-circle-left",
                    "text": "<i class=\"fa fa-2x fa-chevron-circle-left\" aria-hidden=\"true\"></i> chevron circle left"
                },
                {
                    "id": "fa-chevron-circle-right",
                    "text": "<i class=\"fa fa-2x fa-chevron-circle-right\" aria-hidden=\"true\"></i> chevron circle right"
                },
                {
                    "id": "fa-chevron-circle-up",
                    "text": "<i class=\"fa fa-2x fa-chevron-circle-up\" aria-hidden=\"true\"></i> chevron circle up"
                },
                {
                    "id": "fa-chevron-down",
                    "text": "<i class=\"fa fa-2x fa-chevron-down\" aria-hidden=\"true\"></i> chevron down"
                },
                {
                    "id": "fa-chevron-left",
                    "text": "<i class=\"fa fa-2x fa-chevron-left\" aria-hidden=\"true\"></i> chevron left"
                },
                {
                    "id": "fa-chevron-right",
                    "text": "<i class=\"fa fa-2x fa-chevron-right\" aria-hidden=\"true\"></i> chevron right"
                },
                {
                    "id": "fa-chevron-up",
                    "text": "<i class=\"fa fa-2x fa-chevron-up\" aria-hidden=\"true\"></i> chevron up"
                },
                {
                    "id": "fa-child",
                    "text": "<i class=\"fa fa-2x fa-child\" aria-hidden=\"true\"></i> child"
                },
                {
                    "id": "fa-chrome",
                    "text": "<i class=\"fa fa-2x fa-chrome\" aria-hidden=\"true\"></i> chrome"
                },
                {
                    "id": "fa-circle",
                    "text": "<i class=\"fa fa-2x fa-circle\" aria-hidden=\"true\"></i> circle"
                },
                {
                    "id": "fa-circle-o",
                    "text": "<i class=\"fa fa-2x fa-circle-o\" aria-hidden=\"true\"></i> circle o"
                },
                {
                    "id": "fa-circle-o-notch",
                    "text": "<i class=\"fa fa-2x fa-circle-o-notch\" aria-hidden=\"true\"></i> circle o notch"
                },
                {
                    "id": "fa-circle-thin",
                    "text": "<i class=\"fa fa-2x fa-circle-thin\" aria-hidden=\"true\"></i> circle thin"
                },
                {
                    "id": "fa-clipboard",
                    "text": "<i class=\"fa fa-2x fa-clipboard\" aria-hidden=\"true\"></i> clipboard"
                },
                {
                    "id": "fa-clock-o",
                    "text": "<i class=\"fa fa-2x fa-clock-o\" aria-hidden=\"true\"></i> clock o"
                },
                {
                    "id": "fa-clone",
                    "text": "<i class=\"fa fa-2x fa-clone\" aria-hidden=\"true\"></i> clone"
                },
                {
                    "id": "fa-close",
                    "text": "<i class=\"fa fa-2x fa-close\" aria-hidden=\"true\"></i> close"
                },
                {
                    "id": "fa-cloud",
                    "text": "<i class=\"fa fa-2x fa-cloud\" aria-hidden=\"true\"></i> cloud"
                },
                {
                    "id": "fa-cloud-download",
                    "text": "<i class=\"fa fa-2x fa-cloud-download\" aria-hidden=\"true\"></i> cloud download"
                },
                {
                    "id": "fa-cloud-upload",
                    "text": "<i class=\"fa fa-2x fa-cloud-upload\" aria-hidden=\"true\"></i> cloud upload"
                },
                {
                    "id": "fa-cny",
                    "text": "<i class=\"fa fa-2x fa-cny\" aria-hidden=\"true\"></i> cny"
                },
                {
                    "id": "fa-code",
                    "text": "<i class=\"fa fa-2x fa-code\" aria-hidden=\"true\"></i> code"
                },
                {
                    "id": "fa-code-fork",
                    "text": "<i class=\"fa fa-2x fa-code-fork\" aria-hidden=\"true\"></i> code fork"
                },
                {
                    "id": "fa-codepen",
                    "text": "<i class=\"fa fa-2x fa-codepen\" aria-hidden=\"true\"></i> codepen"
                },
                {
                    "id": "fa-codiepie",
                    "text": "<i class=\"fa fa-2x fa-codiepie\" aria-hidden=\"true\"></i> codiepie"
                },
                {
                    "id": "fa-coffee",
                    "text": "<i class=\"fa fa-2x fa-coffee\" aria-hidden=\"true\"></i> coffee"
                },
                {
                    "id": "fa-cog",
                    "text": "<i class=\"fa fa-2x fa-cog\" aria-hidden=\"true\"></i> cog"
                },
                {
                    "id": "fa-cogs",
                    "text": "<i class=\"fa fa-2x fa-cogs\" aria-hidden=\"true\"></i> cogs"
                },
                {
                    "id": "fa-columns",
                    "text": "<i class=\"fa fa-2x fa-columns\" aria-hidden=\"true\"></i> columns"
                },
                {
                    "id": "fa-comment",
                    "text": "<i class=\"fa fa-2x fa-comment\" aria-hidden=\"true\"></i> comment"
                },
                {
                    "id": "fa-comment-o",
                    "text": "<i class=\"fa fa-2x fa-comment-o\" aria-hidden=\"true\"></i> comment o"
                },
                {
                    "id": "fa-commenting",
                    "text": "<i class=\"fa fa-2x fa-commenting\" aria-hidden=\"true\"></i> commenting"
                },
                {
                    "id": "fa-commenting-o",
                    "text": "<i class=\"fa fa-2x fa-commenting-o\" aria-hidden=\"true\"></i> commenting o"
                },
                {
                    "id": "fa-comments",
                    "text": "<i class=\"fa fa-2x fa-comments\" aria-hidden=\"true\"></i> comments"
                },
                {
                    "id": "fa-comments-o",
                    "text": "<i class=\"fa fa-2x fa-comments-o\" aria-hidden=\"true\"></i> comments o"
                },
                {
                    "id": "fa-compass",
                    "text": "<i class=\"fa fa-2x fa-compass\" aria-hidden=\"true\"></i> compass"
                },
                {
                    "id": "fa-compress",
                    "text": "<i class=\"fa fa-2x fa-compress\" aria-hidden=\"true\"></i> compress"
                },
                {
                    "id": "fa-connectdevelop",
                    "text": "<i class=\"fa fa-2x fa-connectdevelop\" aria-hidden=\"true\"></i> connectdevelop"
                },
                {
                    "id": "fa-contao",
                    "text": "<i class=\"fa fa-2x fa-contao\" aria-hidden=\"true\"></i> contao"
                },
                {
                    "id": "fa-copy",
                    "text": "<i class=\"fa fa-2x fa-copy\" aria-hidden=\"true\"></i> copy"
                },
                {
                    "id": "fa-copyright",
                    "text": "<i class=\"fa fa-2x fa-copyright\" aria-hidden=\"true\"></i> copyright"
                },
                {
                    "id": "fa-creative-commons",
                    "text": "<i class=\"fa fa-2x fa-creative-commons\" aria-hidden=\"true\"></i> creative commons"
                },
                {
                    "id": "fa-credit-card",
                    "text": "<i class=\"fa fa-2x fa-credit-card\" aria-hidden=\"true\"></i> credit card"
                },
                {
                    "id": "fa-credit-card-alt",
                    "text": "<i class=\"fa fa-2x fa-credit-card-alt\" aria-hidden=\"true\"></i> credit card alt"
                },
                {
                    "id": "fa-crop",
                    "text": "<i class=\"fa fa-2x fa-crop\" aria-hidden=\"true\"></i> crop"
                },
                {
                    "id": "fa-crosshairs",
                    "text": "<i class=\"fa fa-2x fa-crosshairs\" aria-hidden=\"true\"></i> crosshairs"
                },
                {
                    "id": "fa-css3",
                    "text": "<i class=\"fa fa-2x fa-css3\" aria-hidden=\"true\"></i> css3"
                },
                {
                    "id": "fa-cube",
                    "text": "<i class=\"fa fa-2x fa-cube\" aria-hidden=\"true\"></i> cube"
                },
                {
                    "id": "fa-cubes",
                    "text": "<i class=\"fa fa-2x fa-cubes\" aria-hidden=\"true\"></i> cubes"
                },
                {
                    "id": "fa-cut",
                    "text": "<i class=\"fa fa-2x fa-cut\" aria-hidden=\"true\"></i> cut"
                },
                {
                    "id": "fa-cutlery",
                    "text": "<i class=\"fa fa-2x fa-cutlery\" aria-hidden=\"true\"></i> cutlery"
                },
                {
                    "id": "fa-dashboard",
                    "text": "<i class=\"fa fa-2x fa-dashboard\" aria-hidden=\"true\"></i> dashboard"
                },
                {
                    "id": "fa-dashcube",
                    "text": "<i class=\"fa fa-2x fa-dashcube\" aria-hidden=\"true\"></i> dashcube"
                },
                {
                    "id": "fa-database",
                    "text": "<i class=\"fa fa-2x fa-database\" aria-hidden=\"true\"></i> database"
                },
                {
                    "id": "fa-deaf",
                    "text": "<i class=\"fa fa-2x fa-deaf\" aria-hidden=\"true\"></i> deaf"
                },
                {
                    "id": "fa-deafness",
                    "text": "<i class=\"fa fa-2x fa-deafness\" aria-hidden=\"true\"></i> deafness"
                },
                {
                    "id": "fa-dedent",
                    "text": "<i class=\"fa fa-2x fa-dedent\" aria-hidden=\"true\"></i> dedent"
                },
                {
                    "id": "fa-delicious",
                    "text": "<i class=\"fa fa-2x fa-delicious\" aria-hidden=\"true\"></i> delicious"
                },
                {
                    "id": "fa-desktop",
                    "text": "<i class=\"fa fa-2x fa-desktop\" aria-hidden=\"true\"></i> desktop"
                },
                {
                    "id": "fa-deviantart",
                    "text": "<i class=\"fa fa-2x fa-deviantart\" aria-hidden=\"true\"></i> deviantart"
                },
                {
                    "id": "fa-diamond",
                    "text": "<i class=\"fa fa-2x fa-diamond\" aria-hidden=\"true\"></i> diamond"
                },
                {
                    "id": "fa-digg",
                    "text": "<i class=\"fa fa-2x fa-digg\" aria-hidden=\"true\"></i> digg"
                },
                {
                    "id": "fa-dollar",
                    "text": "<i class=\"fa fa-2x fa-dollar\" aria-hidden=\"true\"></i> dollar"
                },
                {
                    "id": "fa-dot-circle-o",
                    "text": "<i class=\"fa fa-2x fa-dot-circle-o\" aria-hidden=\"true\"></i> dot circle o"
                },
                {
                    "id": "fa-download",
                    "text": "<i class=\"fa fa-2x fa-download\" aria-hidden=\"true\"></i> download"
                },
                {
                    "id": "fa-dribbble",
                    "text": "<i class=\"fa fa-2x fa-dribbble\" aria-hidden=\"true\"></i> dribbble"
                },
                {
                    "id": "fa-drivers-license",
                    "text": "<i class=\"fa fa-2x fa-drivers-license\" aria-hidden=\"true\"></i> drivers license"
                },
                {
                    "id": "fa-drivers-license-o",
                    "text": "<i class=\"fa fa-2x fa-drivers-license-o\" aria-hidden=\"true\"></i> drivers license o"
                },
                {
                    "id": "fa-dropbox",
                    "text": "<i class=\"fa fa-2x fa-dropbox\" aria-hidden=\"true\"></i> dropbox"
                },
                {
                    "id": "fa-drupal",
                    "text": "<i class=\"fa fa-2x fa-drupal\" aria-hidden=\"true\"></i> drupal"
                },
                {
                    "id": "fa-edge",
                    "text": "<i class=\"fa fa-2x fa-edge\" aria-hidden=\"true\"></i> edge"
                },
                {
                    "id": "fa-edit",
                    "text": "<i class=\"fa fa-2x fa-edit\" aria-hidden=\"true\"></i> edit"
                },
                {
                    "id": "fa-eercast",
                    "text": "<i class=\"fa fa-2x fa-eercast\" aria-hidden=\"true\"></i> eercast"
                },
                {
                    "id": "fa-eject",
                    "text": "<i class=\"fa fa-2x fa-eject\" aria-hidden=\"true\"></i> eject"
                },
                {
                    "id": "fa-ellipsis-h",
                    "text": "<i class=\"fa fa-2x fa-ellipsis-h\" aria-hidden=\"true\"></i> ellipsis h"
                },
                {
                    "id": "fa-ellipsis-v",
                    "text": "<i class=\"fa fa-2x fa-ellipsis-v\" aria-hidden=\"true\"></i> ellipsis v"
                },
                {
                    "id": "fa-empire",
                    "text": "<i class=\"fa fa-2x fa-empire\" aria-hidden=\"true\"></i> empire"
                },
                {
                    "id": "fa-envelope",
                    "text": "<i class=\"fa fa-2x fa-envelope\" aria-hidden=\"true\"></i> envelope"
                },
                {
                    "id": "fa-envelope-o",
                    "text": "<i class=\"fa fa-2x fa-envelope-o\" aria-hidden=\"true\"></i> envelope o"
                },
                {
                    "id": "fa-envelope-open",
                    "text": "<i class=\"fa fa-2x fa-envelope-open\" aria-hidden=\"true\"></i> envelope open"
                },
                {
                    "id": "fa-envelope-open-o",
                    "text": "<i class=\"fa fa-2x fa-envelope-open-o\" aria-hidden=\"true\"></i> envelope open o"
                },
                {
                    "id": "fa-envelope-square",
                    "text": "<i class=\"fa fa-2x fa-envelope-square\" aria-hidden=\"true\"></i> envelope square"
                },
                {
                    "id": "fa-envira",
                    "text": "<i class=\"fa fa-2x fa-envira\" aria-hidden=\"true\"></i> envira"
                },
                {
                    "id": "fa-eraser",
                    "text": "<i class=\"fa fa-2x fa-eraser\" aria-hidden=\"true\"></i> eraser"
                },
                {
                    "id": "fa-etsy",
                    "text": "<i class=\"fa fa-2x fa-etsy\" aria-hidden=\"true\"></i> etsy"
                },
                {
                    "id": "fa-eur",
                    "text": "<i class=\"fa fa-2x fa-eur\" aria-hidden=\"true\"></i> eur"
                },
                {
                    "id": "fa-euro",
                    "text": "<i class=\"fa fa-2x fa-euro\" aria-hidden=\"true\"></i> euro"
                },
                {
                    "id": "fa-exchange",
                    "text": "<i class=\"fa fa-2x fa-exchange\" aria-hidden=\"true\"></i> exchange"
                },
                {
                    "id": "fa-exclamation",
                    "text": "<i class=\"fa fa-2x fa-exclamation\" aria-hidden=\"true\"></i> exclamation"
                },
                {
                    "id": "fa-exclamation-circle",
                    "text": "<i class=\"fa fa-2x fa-exclamation-circle\" aria-hidden=\"true\"></i> exclamation circle"
                },
                {
                    "id": "fa-exclamation-triangle",
                    "text": "<i class=\"fa fa-2x fa-exclamation-triangle\" aria-hidden=\"true\"></i> exclamation triangle"
                },
                {
                    "id": "fa-expand",
                    "text": "<i class=\"fa fa-2x fa-expand\" aria-hidden=\"true\"></i> expand"
                },
                {
                    "id": "fa-expeditedssl",
                    "text": "<i class=\"fa fa-2x fa-expeditedssl\" aria-hidden=\"true\"></i> expeditedssl"
                },
                {
                    "id": "fa-external-link",
                    "text": "<i class=\"fa fa-2x fa-external-link\" aria-hidden=\"true\"></i> external link"
                },
                {
                    "id": "fa-external-link-square",
                    "text": "<i class=\"fa fa-2x fa-external-link-square\" aria-hidden=\"true\"></i> external link square"
                },
                {
                    "id": "fa-eye",
                    "text": "<i class=\"fa fa-2x fa-eye\" aria-hidden=\"true\"></i> eye"
                },
                {
                    "id": "fa-eye-slash",
                    "text": "<i class=\"fa fa-2x fa-eye-slash\" aria-hidden=\"true\"></i> eye slash"
                },
                {
                    "id": "fa-eyedropper",
                    "text": "<i class=\"fa fa-2x fa-eyedropper\" aria-hidden=\"true\"></i> eyedropper"
                },
                {
                    "id": "fa-fa",
                    "text": "<i class=\"fa fa-2x fa-fa\" aria-hidden=\"true\"></i> fa"
                },
                {
                    "id": "fa-facebook",
                    "text": "<i class=\"fa fa-2x fa-facebook\" aria-hidden=\"true\"></i> facebook"
                },
                {
                    "id": "fa-facebook-f",
                    "text": "<i class=\"fa fa-2x fa-facebook-f\" aria-hidden=\"true\"></i> facebook f"
                },
                {
                    "id": "fa-facebook-official",
                    "text": "<i class=\"fa fa-2x fa-facebook-official\" aria-hidden=\"true\"></i> facebook official"
                },
                {
                    "id": "fa-facebook-square",
                    "text": "<i class=\"fa fa-2x fa-facebook-square\" aria-hidden=\"true\"></i> facebook square"
                },
                {
                    "id": "fa-fast-backward",
                    "text": "<i class=\"fa fa-2x fa-fast-backward\" aria-hidden=\"true\"></i> fast backward"
                },
                {
                    "id": "fa-fast-forward",
                    "text": "<i class=\"fa fa-2x fa-fast-forward\" aria-hidden=\"true\"></i> fast forward"
                },
                {
                    "id": "fa-fax",
                    "text": "<i class=\"fa fa-2x fa-fax\" aria-hidden=\"true\"></i> fax"
                },
                {
                    "id": "fa-feed",
                    "text": "<i class=\"fa fa-2x fa-feed\" aria-hidden=\"true\"></i> feed"
                },
                {
                    "id": "fa-female",
                    "text": "<i class=\"fa fa-2x fa-female\" aria-hidden=\"true\"></i> female"
                },
                {
                    "id": "fa-fighter-jet",
                    "text": "<i class=\"fa fa-2x fa-fighter-jet\" aria-hidden=\"true\"></i> fighter jet"
                },
                {
                    "id": "fa-file",
                    "text": "<i class=\"fa fa-2x fa-file\" aria-hidden=\"true\"></i> file"
                },
                {
                    "id": "fa-file-archive-o",
                    "text": "<i class=\"fa fa-2x fa-file-archive-o\" aria-hidden=\"true\"></i> file archive o"
                },
                {
                    "id": "fa-file-audio-o",
                    "text": "<i class=\"fa fa-2x fa-file-audio-o\" aria-hidden=\"true\"></i> file audio o"
                },
                {
                    "id": "fa-file-code-o",
                    "text": "<i class=\"fa fa-2x fa-file-code-o\" aria-hidden=\"true\"></i> file code o"
                },
                {
                    "id": "fa-file-excel-o",
                    "text": "<i class=\"fa fa-2x fa-file-excel-o\" aria-hidden=\"true\"></i> file excel o"
                },
                {
                    "id": "fa-file-image-o",
                    "text": "<i class=\"fa fa-2x fa-file-image-o\" aria-hidden=\"true\"></i> file image o"
                },
                {
                    "id": "fa-file-movie-o",
                    "text": "<i class=\"fa fa-2x fa-file-movie-o\" aria-hidden=\"true\"></i> file movie o"
                },
                {
                    "id": "fa-file-o",
                    "text": "<i class=\"fa fa-2x fa-file-o\" aria-hidden=\"true\"></i> file o"
                },
                {
                    "id": "fa-file-pdf-o",
                    "text": "<i class=\"fa fa-2x fa-file-pdf-o\" aria-hidden=\"true\"></i> file pdf o"
                },
                {
                    "id": "fa-file-photo-o",
                    "text": "<i class=\"fa fa-2x fa-file-photo-o\" aria-hidden=\"true\"></i> file photo o"
                },
                {
                    "id": "fa-file-picture-o",
                    "text": "<i class=\"fa fa-2x fa-file-picture-o\" aria-hidden=\"true\"></i> file picture o"
                },
                {
                    "id": "fa-file-powerpoint-o",
                    "text": "<i class=\"fa fa-2x fa-file-powerpoint-o\" aria-hidden=\"true\"></i> file powerpoint o"
                },
                {
                    "id": "fa-file-sound-o",
                    "text": "<i class=\"fa fa-2x fa-file-sound-o\" aria-hidden=\"true\"></i> file sound o"
                },
                {
                    "id": "fa-file-text",
                    "text": "<i class=\"fa fa-2x fa-file-text\" aria-hidden=\"true\"></i> file text"
                },
                {
                    "id": "fa-file-text-o",
                    "text": "<i class=\"fa fa-2x fa-file-text-o\" aria-hidden=\"true\"></i> file text o"
                },
                {
                    "id": "fa-file-video-o",
                    "text": "<i class=\"fa fa-2x fa-file-video-o\" aria-hidden=\"true\"></i> file video o"
                },
                {
                    "id": "fa-file-word-o",
                    "text": "<i class=\"fa fa-2x fa-file-word-o\" aria-hidden=\"true\"></i> file word o"
                },
                {
                    "id": "fa-file-zip-o",
                    "text": "<i class=\"fa fa-2x fa-file-zip-o\" aria-hidden=\"true\"></i> file zip o"
                },
                {
                    "id": "fa-files-o",
                    "text": "<i class=\"fa fa-2x fa-files-o\" aria-hidden=\"true\"></i> files o"
                },
                {
                    "id": "fa-film",
                    "text": "<i class=\"fa fa-2x fa-film\" aria-hidden=\"true\"></i> film"
                },
                {
                    "id": "fa-filter",
                    "text": "<i class=\"fa fa-2x fa-filter\" aria-hidden=\"true\"></i> filter"
                },
                {
                    "id": "fa-fire",
                    "text": "<i class=\"fa fa-2x fa-fire\" aria-hidden=\"true\"></i> fire"
                },
                {
                    "id": "fa-fire-extinguisher",
                    "text": "<i class=\"fa fa-2x fa-fire-extinguisher\" aria-hidden=\"true\"></i> fire extinguisher"
                },
                {
                    "id": "fa-firefox",
                    "text": "<i class=\"fa fa-2x fa-firefox\" aria-hidden=\"true\"></i> firefox"
                },
                {
                    "id": "fa-first-order",
                    "text": "<i class=\"fa fa-2x fa-first-order\" aria-hidden=\"true\"></i> first order"
                },
                {
                    "id": "fa-flag",
                    "text": "<i class=\"fa fa-2x fa-flag\" aria-hidden=\"true\"></i> flag"
                },
                {
                    "id": "fa-flag-checkered",
                    "text": "<i class=\"fa fa-2x fa-flag-checkered\" aria-hidden=\"true\"></i> flag checkered"
                },
                {
                    "id": "fa-flag-o",
                    "text": "<i class=\"fa fa-2x fa-flag-o\" aria-hidden=\"true\"></i> flag o"
                },
                {
                    "id": "fa-flash",
                    "text": "<i class=\"fa fa-2x fa-flash\" aria-hidden=\"true\"></i> flash"
                },
                {
                    "id": "fa-flask",
                    "text": "<i class=\"fa fa-2x fa-flask\" aria-hidden=\"true\"></i> flask"
                },
                {
                    "id": "fa-flickr",
                    "text": "<i class=\"fa fa-2x fa-flickr\" aria-hidden=\"true\"></i> flickr"
                },
                {
                    "id": "fa-floppy-o",
                    "text": "<i class=\"fa fa-2x fa-floppy-o\" aria-hidden=\"true\"></i> floppy o"
                },
                {
                    "id": "fa-folder",
                    "text": "<i class=\"fa fa-2x fa-folder\" aria-hidden=\"true\"></i> folder"
                },
                {
                    "id": "fa-folder-o",
                    "text": "<i class=\"fa fa-2x fa-folder-o\" aria-hidden=\"true\"></i> folder o"
                },
                {
                    "id": "fa-folder-open",
                    "text": "<i class=\"fa fa-2x fa-folder-open\" aria-hidden=\"true\"></i> folder open"
                },
                {
                    "id": "fa-folder-open-o",
                    "text": "<i class=\"fa fa-2x fa-folder-open-o\" aria-hidden=\"true\"></i> folder open o"
                },
                {
                    "id": "fa-font",
                    "text": "<i class=\"fa fa-2x fa-font\" aria-hidden=\"true\"></i> font"
                },
                {
                    "id": "fa-font-awesome",
                    "text": "<i class=\"fa fa-2x fa-font-awesome\" aria-hidden=\"true\"></i> font awesome"
                },
                {
                    "id": "fa-fonticons",
                    "text": "<i class=\"fa fa-2x fa-fonticons\" aria-hidden=\"true\"></i> fonticons"
                },
                {
                    "id": "fa-fort-awesome",
                    "text": "<i class=\"fa fa-2x fa-fort-awesome\" aria-hidden=\"true\"></i> fort awesome"
                },
                {
                    "id": "fa-forumbee",
                    "text": "<i class=\"fa fa-2x fa-forumbee\" aria-hidden=\"true\"></i> forumbee"
                },
                {
                    "id": "fa-forward",
                    "text": "<i class=\"fa fa-2x fa-forward\" aria-hidden=\"true\"></i> forward"
                },
                {
                    "id": "fa-foursquare",
                    "text": "<i class=\"fa fa-2x fa-foursquare\" aria-hidden=\"true\"></i> foursquare"
                },
                {
                    "id": "fa-free-code-camp",
                    "text": "<i class=\"fa fa-2x fa-free-code-camp\" aria-hidden=\"true\"></i> free code camp"
                },
                {
                    "id": "fa-frown-o",
                    "text": "<i class=\"fa fa-2x fa-frown-o\" aria-hidden=\"true\"></i> frown o"
                },
                {
                    "id": "fa-futbol-o",
                    "text": "<i class=\"fa fa-2x fa-futbol-o\" aria-hidden=\"true\"></i> futbol o"
                },
                {
                    "id": "fa-gamepad",
                    "text": "<i class=\"fa fa-2x fa-gamepad\" aria-hidden=\"true\"></i> gamepad"
                },
                {
                    "id": "fa-gavel",
                    "text": "<i class=\"fa fa-2x fa-gavel\" aria-hidden=\"true\"></i> gavel"
                },
                {
                    "id": "fa-gbp",
                    "text": "<i class=\"fa fa-2x fa-gbp\" aria-hidden=\"true\"></i> gbp"
                },
                {
                    "id": "fa-ge",
                    "text": "<i class=\"fa fa-2x fa-ge\" aria-hidden=\"true\"></i> ge"
                },
                {
                    "id": "fa-gear",
                    "text": "<i class=\"fa fa-2x fa-gear\" aria-hidden=\"true\"></i> gear"
                },
                {
                    "id": "fa-gears",
                    "text": "<i class=\"fa fa-2x fa-gears\" aria-hidden=\"true\"></i> gears"
                },
                {
                    "id": "fa-genderless",
                    "text": "<i class=\"fa fa-2x fa-genderless\" aria-hidden=\"true\"></i> genderless"
                },
                {
                    "id": "fa-get-pocket",
                    "text": "<i class=\"fa fa-2x fa-get-pocket\" aria-hidden=\"true\"></i> get pocket"
                },
                {
                    "id": "fa-gg",
                    "text": "<i class=\"fa fa-2x fa-gg\" aria-hidden=\"true\"></i> gg"
                },
                {
                    "id": "fa-gg-circle",
                    "text": "<i class=\"fa fa-2x fa-gg-circle\" aria-hidden=\"true\"></i> gg circle"
                },
                {
                    "id": "fa-gift",
                    "text": "<i class=\"fa fa-2x fa-gift\" aria-hidden=\"true\"></i> gift"
                },
                {
                    "id": "fa-git",
                    "text": "<i class=\"fa fa-2x fa-git\" aria-hidden=\"true\"></i> git"
                },
                {
                    "id": "fa-git-square",
                    "text": "<i class=\"fa fa-2x fa-git-square\" aria-hidden=\"true\"></i> git square"
                },
                {
                    "id": "fa-github",
                    "text": "<i class=\"fa fa-2x fa-github\" aria-hidden=\"true\"></i> github"
                },
                {
                    "id": "fa-github-alt",
                    "text": "<i class=\"fa fa-2x fa-github-alt\" aria-hidden=\"true\"></i> github alt"
                },
                {
                    "id": "fa-github-square",
                    "text": "<i class=\"fa fa-2x fa-github-square\" aria-hidden=\"true\"></i> github square"
                },
                {
                    "id": "fa-gitlab",
                    "text": "<i class=\"fa fa-2x fa-gitlab\" aria-hidden=\"true\"></i> gitlab"
                },
                {
                    "id": "fa-gittip",
                    "text": "<i class=\"fa fa-2x fa-gittip\" aria-hidden=\"true\"></i> gittip"
                },
                {
                    "id": "fa-glass",
                    "text": "<i class=\"fa fa-2x fa-glass\" aria-hidden=\"true\"></i> glass"
                },
                {
                    "id": "fa-glide",
                    "text": "<i class=\"fa fa-2x fa-glide\" aria-hidden=\"true\"></i> glide"
                },
                {
                    "id": "fa-glide-g",
                    "text": "<i class=\"fa fa-2x fa-glide-g\" aria-hidden=\"true\"></i> glide g"
                },
                {
                    "id": "fa-globe",
                    "text": "<i class=\"fa fa-2x fa-globe\" aria-hidden=\"true\"></i> globe"
                },
                {
                    "id": "fa-google",
                    "text": "<i class=\"fa fa-2x fa-google\" aria-hidden=\"true\"></i> google"
                },
                {
                    "id": "fa-google-plus",
                    "text": "<i class=\"fa fa-2x fa-google-plus\" aria-hidden=\"true\"></i> google plus"
                },
                {
                    "id": "fa-google-plus-circle",
                    "text": "<i class=\"fa fa-2x fa-google-plus-circle\" aria-hidden=\"true\"></i> google plus circle"
                },
                {
                    "id": "fa-google-plus-official",
                    "text": "<i class=\"fa fa-2x fa-google-plus-official\" aria-hidden=\"true\"></i> google plus official"
                },
                {
                    "id": "fa-google-plus-square",
                    "text": "<i class=\"fa fa-2x fa-google-plus-square\" aria-hidden=\"true\"></i> google plus square"
                },
                {
                    "id": "fa-google-wallet",
                    "text": "<i class=\"fa fa-2x fa-google-wallet\" aria-hidden=\"true\"></i> google wallet"
                },
                {
                    "id": "fa-graduation-cap",
                    "text": "<i class=\"fa fa-2x fa-graduation-cap\" aria-hidden=\"true\"></i> graduation cap"
                },
                {
                    "id": "fa-gratipay",
                    "text": "<i class=\"fa fa-2x fa-gratipay\" aria-hidden=\"true\"></i> gratipay"
                },
                {
                    "id": "fa-grav",
                    "text": "<i class=\"fa fa-2x fa-grav\" aria-hidden=\"true\"></i> grav"
                },
                {
                    "id": "fa-group",
                    "text": "<i class=\"fa fa-2x fa-group\" aria-hidden=\"true\"></i> group"
                },
                {
                    "id": "fa-h-square",
                    "text": "<i class=\"fa fa-2x fa-h-square\" aria-hidden=\"true\"></i> h square"
                },
                {
                    "id": "fa-hacker-news",
                    "text": "<i class=\"fa fa-2x fa-hacker-news\" aria-hidden=\"true\"></i> hacker news"
                },
                {
                    "id": "fa-hand-grab-o",
                    "text": "<i class=\"fa fa-2x fa-hand-grab-o\" aria-hidden=\"true\"></i> hand grab o"
                },
                {
                    "id": "fa-hand-lizard-o",
                    "text": "<i class=\"fa fa-2x fa-hand-lizard-o\" aria-hidden=\"true\"></i> hand lizard o"
                },
                {
                    "id": "fa-hand-o-down",
                    "text": "<i class=\"fa fa-2x fa-hand-o-down\" aria-hidden=\"true\"></i> hand o down"
                },
                {
                    "id": "fa-hand-o-left",
                    "text": "<i class=\"fa fa-2x fa-hand-o-left\" aria-hidden=\"true\"></i> hand o left"
                },
                {
                    "id": "fa-hand-o-right",
                    "text": "<i class=\"fa fa-2x fa-hand-o-right\" aria-hidden=\"true\"></i> hand o right"
                },
                {
                    "id": "fa-hand-o-up",
                    "text": "<i class=\"fa fa-2x fa-hand-o-up\" aria-hidden=\"true\"></i> hand o up"
                },
                {
                    "id": "fa-hand-paper-o",
                    "text": "<i class=\"fa fa-2x fa-hand-paper-o\" aria-hidden=\"true\"></i> hand paper o"
                },
                {
                    "id": "fa-hand-peace-o",
                    "text": "<i class=\"fa fa-2x fa-hand-peace-o\" aria-hidden=\"true\"></i> hand peace o"
                },
                {
                    "id": "fa-hand-pointer-o",
                    "text": "<i class=\"fa fa-2x fa-hand-pointer-o\" aria-hidden=\"true\"></i> hand pointer o"
                },
                {
                    "id": "fa-hand-rock-o",
                    "text": "<i class=\"fa fa-2x fa-hand-rock-o\" aria-hidden=\"true\"></i> hand rock o"
                },
                {
                    "id": "fa-hand-scissors-o",
                    "text": "<i class=\"fa fa-2x fa-hand-scissors-o\" aria-hidden=\"true\"></i> hand scissors o"
                },
                {
                    "id": "fa-hand-spock-o",
                    "text": "<i class=\"fa fa-2x fa-hand-spock-o\" aria-hidden=\"true\"></i> hand spock o"
                },
                {
                    "id": "fa-hand-stop-o",
                    "text": "<i class=\"fa fa-2x fa-hand-stop-o\" aria-hidden=\"true\"></i> hand stop o"
                },
                {
                    "id": "fa-handshake-o",
                    "text": "<i class=\"fa fa-2x fa-handshake-o\" aria-hidden=\"true\"></i> handshake o"
                },
                {
                    "id": "fa-hard-of-hearing",
                    "text": "<i class=\"fa fa-2x fa-hard-of-hearing\" aria-hidden=\"true\"></i> hard of hearing"
                },
                {
                    "id": "fa-hashtag",
                    "text": "<i class=\"fa fa-2x fa-hashtag\" aria-hidden=\"true\"></i> hashtag"
                },
                {
                    "id": "fa-hdd-o",
                    "text": "<i class=\"fa fa-2x fa-hdd-o\" aria-hidden=\"true\"></i> hdd o"
                },
                {
                    "id": "fa-header",
                    "text": "<i class=\"fa fa-2x fa-header\" aria-hidden=\"true\"></i> header"
                },
                {
                    "id": "fa-headphones",
                    "text": "<i class=\"fa fa-2x fa-headphones\" aria-hidden=\"true\"></i> headphones"
                },
                {
                    "id": "fa-heart",
                    "text": "<i class=\"fa fa-2x fa-heart\" aria-hidden=\"true\"></i> heart"
                },
                {
                    "id": "fa-heart-o",
                    "text": "<i class=\"fa fa-2x fa-heart-o\" aria-hidden=\"true\"></i> heart o"
                },
                {
                    "id": "fa-heartbeat",
                    "text": "<i class=\"fa fa-2x fa-heartbeat\" aria-hidden=\"true\"></i> heartbeat"
                },
                {
                    "id": "fa-history",
                    "text": "<i class=\"fa fa-2x fa-history\" aria-hidden=\"true\"></i> history"
                },
                {
                    "id": "fa-home",
                    "text": "<i class=\"fa fa-2x fa-home\" aria-hidden=\"true\"></i> home"
                },
                {
                    "id": "fa-hospital-o",
                    "text": "<i class=\"fa fa-2x fa-hospital-o\" aria-hidden=\"true\"></i> hospital o"
                },
                {
                    "id": "fa-hotel",
                    "text": "<i class=\"fa fa-2x fa-hotel\" aria-hidden=\"true\"></i> hotel"
                },
                {
                    "id": "fa-hourglass",
                    "text": "<i class=\"fa fa-2x fa-hourglass\" aria-hidden=\"true\"></i> hourglass"
                },
                {
                    "id": "fa-hourglass-1",
                    "text": "<i class=\"fa fa-2x fa-hourglass-1\" aria-hidden=\"true\"></i> hourglass 1"
                },
                {
                    "id": "fa-hourglass-2",
                    "text": "<i class=\"fa fa-2x fa-hourglass-2\" aria-hidden=\"true\"></i> hourglass 2"
                },
                {
                    "id": "fa-hourglass-3",
                    "text": "<i class=\"fa fa-2x fa-hourglass-3\" aria-hidden=\"true\"></i> hourglass 3"
                },
                {
                    "id": "fa-hourglass-end",
                    "text": "<i class=\"fa fa-2x fa-hourglass-end\" aria-hidden=\"true\"></i> hourglass end"
                },
                {
                    "id": "fa-hourglass-half",
                    "text": "<i class=\"fa fa-2x fa-hourglass-half\" aria-hidden=\"true\"></i> hourglass half"
                },
                {
                    "id": "fa-hourglass-o",
                    "text": "<i class=\"fa fa-2x fa-hourglass-o\" aria-hidden=\"true\"></i> hourglass o"
                },
                {
                    "id": "fa-hourglass-start",
                    "text": "<i class=\"fa fa-2x fa-hourglass-start\" aria-hidden=\"true\"></i> hourglass start"
                },
                {
                    "id": "fa-houzz",
                    "text": "<i class=\"fa fa-2x fa-houzz\" aria-hidden=\"true\"></i> houzz"
                },
                {
                    "id": "fa-html5",
                    "text": "<i class=\"fa fa-2x fa-html5\" aria-hidden=\"true\"></i> html5"
                },
                {
                    "id": "fa-i-cursor",
                    "text": "<i class=\"fa fa-2x fa-i-cursor\" aria-hidden=\"true\"></i> i cursor"
                },
                {
                    "id": "fa-id-badge",
                    "text": "<i class=\"fa fa-2x fa-id-badge\" aria-hidden=\"true\"></i> id badge"
                },
                {
                    "id": "fa-id-card",
                    "text": "<i class=\"fa fa-2x fa-id-card\" aria-hidden=\"true\"></i> id card"
                },
                {
                    "id": "fa-id-card-o",
                    "text": "<i class=\"fa fa-2x fa-id-card-o\" aria-hidden=\"true\"></i> id card o"
                },
                {
                    "id": "fa-ils",
                    "text": "<i class=\"fa fa-2x fa-ils\" aria-hidden=\"true\"></i> ils"
                },
                {
                    "id": "fa-image",
                    "text": "<i class=\"fa fa-2x fa-image\" aria-hidden=\"true\"></i> image"
                },
                {
                    "id": "fa-imdb",
                    "text": "<i class=\"fa fa-2x fa-imdb\" aria-hidden=\"true\"></i> imdb"
                },
                {
                    "id": "fa-inbox",
                    "text": "<i class=\"fa fa-2x fa-inbox\" aria-hidden=\"true\"></i> inbox"
                },
                {
                    "id": "fa-indent",
                    "text": "<i class=\"fa fa-2x fa-indent\" aria-hidden=\"true\"></i> indent"
                },
                {
                    "id": "fa-industry",
                    "text": "<i class=\"fa fa-2x fa-industry\" aria-hidden=\"true\"></i> industry"
                },
                {
                    "id": "fa-info",
                    "text": "<i class=\"fa fa-2x fa-info\" aria-hidden=\"true\"></i> info"
                },
                {
                    "id": "fa-info-circle",
                    "text": "<i class=\"fa fa-2x fa-info-circle\" aria-hidden=\"true\"></i> info circle"
                },
                {
                    "id": "fa-inr",
                    "text": "<i class=\"fa fa-2x fa-inr\" aria-hidden=\"true\"></i> inr"
                },
                {
                    "id": "fa-instagram",
                    "text": "<i class=\"fa fa-2x fa-instagram\" aria-hidden=\"true\"></i> instagram"
                },
                {
                    "id": "fa-institution",
                    "text": "<i class=\"fa fa-2x fa-institution\" aria-hidden=\"true\"></i> institution"
                },
                {
                    "id": "fa-internet-explorer",
                    "text": "<i class=\"fa fa-2x fa-internet-explorer\" aria-hidden=\"true\"></i> internet explorer"
                },
                {
                    "id": "fa-intersex",
                    "text": "<i class=\"fa fa-2x fa-intersex\" aria-hidden=\"true\"></i> intersex"
                },
                {
                    "id": "fa-ioxhost",
                    "text": "<i class=\"fa fa-2x fa-ioxhost\" aria-hidden=\"true\"></i> ioxhost"
                },
                {
                    "id": "fa-italic",
                    "text": "<i class=\"fa fa-2x fa-italic\" aria-hidden=\"true\"></i> italic"
                },
                {
                    "id": "fa-joomla",
                    "text": "<i class=\"fa fa-2x fa-joomla\" aria-hidden=\"true\"></i> joomla"
                },
                {
                    "id": "fa-jpy",
                    "text": "<i class=\"fa fa-2x fa-jpy\" aria-hidden=\"true\"></i> jpy"
                },
                {
                    "id": "fa-jsfiddle",
                    "text": "<i class=\"fa fa-2x fa-jsfiddle\" aria-hidden=\"true\"></i> jsfiddle"
                },
                {
                    "id": "fa-key",
                    "text": "<i class=\"fa fa-2x fa-key\" aria-hidden=\"true\"></i> key"
                },
                {
                    "id": "fa-keyboard-o",
                    "text": "<i class=\"fa fa-2x fa-keyboard-o\" aria-hidden=\"true\"></i> keyboard o"
                },
                {
                    "id": "fa-krw",
                    "text": "<i class=\"fa fa-2x fa-krw\" aria-hidden=\"true\"></i> krw"
                },
                {
                    "id": "fa-language",
                    "text": "<i class=\"fa fa-2x fa-language\" aria-hidden=\"true\"></i> language"
                },
                {
                    "id": "fa-laptop",
                    "text": "<i class=\"fa fa-2x fa-laptop\" aria-hidden=\"true\"></i> laptop"
                },
                {
                    "id": "fa-lastfm",
                    "text": "<i class=\"fa fa-2x fa-lastfm\" aria-hidden=\"true\"></i> lastfm"
                },
                {
                    "id": "fa-lastfm-square",
                    "text": "<i class=\"fa fa-2x fa-lastfm-square\" aria-hidden=\"true\"></i> lastfm square"
                },
                {
                    "id": "fa-leaf",
                    "text": "<i class=\"fa fa-2x fa-leaf\" aria-hidden=\"true\"></i> leaf"
                },
                {
                    "id": "fa-leanpub",
                    "text": "<i class=\"fa fa-2x fa-leanpub\" aria-hidden=\"true\"></i> leanpub"
                },
                {
                    "id": "fa-legal",
                    "text": "<i class=\"fa fa-2x fa-legal\" aria-hidden=\"true\"></i> legal"
                },
                {
                    "id": "fa-lemon-o",
                    "text": "<i class=\"fa fa-2x fa-lemon-o\" aria-hidden=\"true\"></i> lemon o"
                },
                {
                    "id": "fa-level-down",
                    "text": "<i class=\"fa fa-2x fa-level-down\" aria-hidden=\"true\"></i> level down"
                },
                {
                    "id": "fa-level-up",
                    "text": "<i class=\"fa fa-2x fa-level-up\" aria-hidden=\"true\"></i> level up"
                },
                {
                    "id": "fa-life-bouy",
                    "text": "<i class=\"fa fa-2x fa-life-bouy\" aria-hidden=\"true\"></i> life bouy"
                },
                {
                    "id": "fa-life-buoy",
                    "text": "<i class=\"fa fa-2x fa-life-buoy\" aria-hidden=\"true\"></i> life buoy"
                },
                {
                    "id": "fa-life-ring",
                    "text": "<i class=\"fa fa-2x fa-life-ring\" aria-hidden=\"true\"></i> life ring"
                },
                {
                    "id": "fa-life-saver",
                    "text": "<i class=\"fa fa-2x fa-life-saver\" aria-hidden=\"true\"></i> life saver"
                },
                {
                    "id": "fa-lightbulb-o",
                    "text": "<i class=\"fa fa-2x fa-lightbulb-o\" aria-hidden=\"true\"></i> lightbulb o"
                },
                {
                    "id": "fa-line-chart",
                    "text": "<i class=\"fa fa-2x fa-line-chart\" aria-hidden=\"true\"></i> line chart"
                },
                {
                    "id": "fa-link",
                    "text": "<i class=\"fa fa-2x fa-link\" aria-hidden=\"true\"></i> link"
                },
                {
                    "id": "fa-linkedin",
                    "text": "<i class=\"fa fa-2x fa-linkedin\" aria-hidden=\"true\"></i> linkedin"
                },
                {
                    "id": "fa-linkedin-square",
                    "text": "<i class=\"fa fa-2x fa-linkedin-square\" aria-hidden=\"true\"></i> linkedin square"
                },
                {
                    "id": "fa-linode",
                    "text": "<i class=\"fa fa-2x fa-linode\" aria-hidden=\"true\"></i> linode"
                },
                {
                    "id": "fa-linux",
                    "text": "<i class=\"fa fa-2x fa-linux\" aria-hidden=\"true\"></i> linux"
                },
                {
                    "id": "fa-list",
                    "text": "<i class=\"fa fa-2x fa-list\" aria-hidden=\"true\"></i> list"
                },
                {
                    "id": "fa-list-alt",
                    "text": "<i class=\"fa fa-2x fa-list-alt\" aria-hidden=\"true\"></i> list alt"
                },
                {
                    "id": "fa-list-ol",
                    "text": "<i class=\"fa fa-2x fa-list-ol\" aria-hidden=\"true\"></i> list ol"
                },
                {
                    "id": "fa-list-ul",
                    "text": "<i class=\"fa fa-2x fa-list-ul\" aria-hidden=\"true\"></i> list ul"
                },
                {
                    "id": "fa-location-arrow",
                    "text": "<i class=\"fa fa-2x fa-location-arrow\" aria-hidden=\"true\"></i> location arrow"
                },
                {
                    "id": "fa-lock",
                    "text": "<i class=\"fa fa-2x fa-lock\" aria-hidden=\"true\"></i> lock"
                },
                {
                    "id": "fa-long-arrow-down",
                    "text": "<i class=\"fa fa-2x fa-long-arrow-down\" aria-hidden=\"true\"></i> long arrow down"
                },
                {
                    "id": "fa-long-arrow-left",
                    "text": "<i class=\"fa fa-2x fa-long-arrow-left\" aria-hidden=\"true\"></i> long arrow left"
                },
                {
                    "id": "fa-long-arrow-right",
                    "text": "<i class=\"fa fa-2x fa-long-arrow-right\" aria-hidden=\"true\"></i> long arrow right"
                },
                {
                    "id": "fa-long-arrow-up",
                    "text": "<i class=\"fa fa-2x fa-long-arrow-up\" aria-hidden=\"true\"></i> long arrow up"
                },
                {
                    "id": "fa-low-vision",
                    "text": "<i class=\"fa fa-2x fa-low-vision\" aria-hidden=\"true\"></i> low vision"
                },
                {
                    "id": "fa-magic",
                    "text": "<i class=\"fa fa-2x fa-magic\" aria-hidden=\"true\"></i> magic"
                },
                {
                    "id": "fa-magnet",
                    "text": "<i class=\"fa fa-2x fa-magnet\" aria-hidden=\"true\"></i> magnet"
                },
                {
                    "id": "fa-mail-forward",
                    "text": "<i class=\"fa fa-2x fa-mail-forward\" aria-hidden=\"true\"></i> mail forward"
                },
                {
                    "id": "fa-mail-reply",
                    "text": "<i class=\"fa fa-2x fa-mail-reply\" aria-hidden=\"true\"></i> mail reply"
                },
                {
                    "id": "fa-mail-reply-all",
                    "text": "<i class=\"fa fa-2x fa-mail-reply-all\" aria-hidden=\"true\"></i> mail reply all"
                },
                {
                    "id": "fa-male",
                    "text": "<i class=\"fa fa-2x fa-male\" aria-hidden=\"true\"></i> male"
                },
                {
                    "id": "fa-map",
                    "text": "<i class=\"fa fa-2x fa-map\" aria-hidden=\"true\"></i> map"
                },
                {
                    "id": "fa-map-marker",
                    "text": "<i class=\"fa fa-2x fa-map-marker\" aria-hidden=\"true\"></i> map marker"
                },
                {
                    "id": "fa-map-o",
                    "text": "<i class=\"fa fa-2x fa-map-o\" aria-hidden=\"true\"></i> map o"
                },
                {
                    "id": "fa-map-pin",
                    "text": "<i class=\"fa fa-2x fa-map-pin\" aria-hidden=\"true\"></i> map pin"
                },
                {
                    "id": "fa-map-signs",
                    "text": "<i class=\"fa fa-2x fa-map-signs\" aria-hidden=\"true\"></i> map signs"
                },
                {
                    "id": "fa-mars",
                    "text": "<i class=\"fa fa-2x fa-mars\" aria-hidden=\"true\"></i> mars"
                },
                {
                    "id": "fa-mars-double",
                    "text": "<i class=\"fa fa-2x fa-mars-double\" aria-hidden=\"true\"></i> mars double"
                },
                {
                    "id": "fa-mars-stroke",
                    "text": "<i class=\"fa fa-2x fa-mars-stroke\" aria-hidden=\"true\"></i> mars stroke"
                },
                {
                    "id": "fa-mars-stroke-h",
                    "text": "<i class=\"fa fa-2x fa-mars-stroke-h\" aria-hidden=\"true\"></i> mars stroke h"
                },
                {
                    "id": "fa-mars-stroke-v",
                    "text": "<i class=\"fa fa-2x fa-mars-stroke-v\" aria-hidden=\"true\"></i> mars stroke v"
                },
                {
                    "id": "fa-maxcdn",
                    "text": "<i class=\"fa fa-2x fa-maxcdn\" aria-hidden=\"true\"></i> maxcdn"
                },
                {
                    "id": "fa-meanpath",
                    "text": "<i class=\"fa fa-2x fa-meanpath\" aria-hidden=\"true\"></i> meanpath"
                },
                {
                    "id": "fa-medium",
                    "text": "<i class=\"fa fa-2x fa-medium\" aria-hidden=\"true\"></i> medium"
                },
                {
                    "id": "fa-medkit",
                    "text": "<i class=\"fa fa-2x fa-medkit\" aria-hidden=\"true\"></i> medkit"
                },
                {
                    "id": "fa-meetup",
                    "text": "<i class=\"fa fa-2x fa-meetup\" aria-hidden=\"true\"></i> meetup"
                },
                {
                    "id": "fa-meh-o",
                    "text": "<i class=\"fa fa-2x fa-meh-o\" aria-hidden=\"true\"></i> meh o"
                },
                {
                    "id": "fa-mercury",
                    "text": "<i class=\"fa fa-2x fa-mercury\" aria-hidden=\"true\"></i> mercury"
                },
                {
                    "id": "fa-microchip",
                    "text": "<i class=\"fa fa-2x fa-microchip\" aria-hidden=\"true\"></i> microchip"
                },
                {
                    "id": "fa-microphone",
                    "text": "<i class=\"fa fa-2x fa-microphone\" aria-hidden=\"true\"></i> microphone"
                },
                {
                    "id": "fa-microphone-slash",
                    "text": "<i class=\"fa fa-2x fa-microphone-slash\" aria-hidden=\"true\"></i> microphone slash"
                },
                {
                    "id": "fa-minus",
                    "text": "<i class=\"fa fa-2x fa-minus\" aria-hidden=\"true\"></i> minus"
                },
                {
                    "id": "fa-minus-circle",
                    "text": "<i class=\"fa fa-2x fa-minus-circle\" aria-hidden=\"true\"></i> minus circle"
                },
                {
                    "id": "fa-minus-square",
                    "text": "<i class=\"fa fa-2x fa-minus-square\" aria-hidden=\"true\"></i> minus square"
                },
                {
                    "id": "fa-minus-square-o",
                    "text": "<i class=\"fa fa-2x fa-minus-square-o\" aria-hidden=\"true\"></i> minus square o"
                },
                {
                    "id": "fa-mixcloud",
                    "text": "<i class=\"fa fa-2x fa-mixcloud\" aria-hidden=\"true\"></i> mixcloud"
                },
                {
                    "id": "fa-mobile",
                    "text": "<i class=\"fa fa-2x fa-mobile\" aria-hidden=\"true\"></i> mobile"
                },
                {
                    "id": "fa-mobile-phone",
                    "text": "<i class=\"fa fa-2x fa-mobile-phone\" aria-hidden=\"true\"></i> mobile phone"
                },
                {
                    "id": "fa-modx",
                    "text": "<i class=\"fa fa-2x fa-modx\" aria-hidden=\"true\"></i> modx"
                },
                {
                    "id": "fa-money",
                    "text": "<i class=\"fa fa-2x fa-money\" aria-hidden=\"true\"></i> money"
                },
                {
                    "id": "fa-moon-o",
                    "text": "<i class=\"fa fa-2x fa-moon-o\" aria-hidden=\"true\"></i> moon o"
                },
                {
                    "id": "fa-mortar-board",
                    "text": "<i class=\"fa fa-2x fa-mortar-board\" aria-hidden=\"true\"></i> mortar board"
                },
                {
                    "id": "fa-motorcycle",
                    "text": "<i class=\"fa fa-2x fa-motorcycle\" aria-hidden=\"true\"></i> motorcycle"
                },
                {
                    "id": "fa-mouse-pointer",
                    "text": "<i class=\"fa fa-2x fa-mouse-pointer\" aria-hidden=\"true\"></i> mouse pointer"
                },
                {
                    "id": "fa-music",
                    "text": "<i class=\"fa fa-2x fa-music\" aria-hidden=\"true\"></i> music"
                },
                {
                    "id": "fa-navicon",
                    "text": "<i class=\"fa fa-2x fa-navicon\" aria-hidden=\"true\"></i> navicon"
                },
                {
                    "id": "fa-neuter",
                    "text": "<i class=\"fa fa-2x fa-neuter\" aria-hidden=\"true\"></i> neuter"
                },
                {
                    "id": "fa-newspaper-o",
                    "text": "<i class=\"fa fa-2x fa-newspaper-o\" aria-hidden=\"true\"></i> newspaper o"
                },
                {
                    "id": "fa-object-group",
                    "text": "<i class=\"fa fa-2x fa-object-group\" aria-hidden=\"true\"></i> object group"
                },
                {
                    "id": "fa-object-ungroup",
                    "text": "<i class=\"fa fa-2x fa-object-ungroup\" aria-hidden=\"true\"></i> object ungroup"
                },
                {
                    "id": "fa-odnoklassniki",
                    "text": "<i class=\"fa fa-2x fa-odnoklassniki\" aria-hidden=\"true\"></i> odnoklassniki"
                },
                {
                    "id": "fa-odnoklassniki-square",
                    "text": "<i class=\"fa fa-2x fa-odnoklassniki-square\" aria-hidden=\"true\"></i> odnoklassniki square"
                },
                {
                    "id": "fa-opencart",
                    "text": "<i class=\"fa fa-2x fa-opencart\" aria-hidden=\"true\"></i> opencart"
                },
                {
                    "id": "fa-openid",
                    "text": "<i class=\"fa fa-2x fa-openid\" aria-hidden=\"true\"></i> openid"
                },
                {
                    "id": "fa-opera",
                    "text": "<i class=\"fa fa-2x fa-opera\" aria-hidden=\"true\"></i> opera"
                },
                {
                    "id": "fa-optin-monster",
                    "text": "<i class=\"fa fa-2x fa-optin-monster\" aria-hidden=\"true\"></i> optin monster"
                },
                {
                    "id": "fa-outdent",
                    "text": "<i class=\"fa fa-2x fa-outdent\" aria-hidden=\"true\"></i> outdent"
                },
                {
                    "id": "fa-pagelines",
                    "text": "<i class=\"fa fa-2x fa-pagelines\" aria-hidden=\"true\"></i> pagelines"
                },
                {
                    "id": "fa-paint-brush",
                    "text": "<i class=\"fa fa-2x fa-paint-brush\" aria-hidden=\"true\"></i> paint brush"
                },
                {
                    "id": "fa-paper-plane",
                    "text": "<i class=\"fa fa-2x fa-paper-plane\" aria-hidden=\"true\"></i> paper plane"
                },
                {
                    "id": "fa-paper-plane-o",
                    "text": "<i class=\"fa fa-2x fa-paper-plane-o\" aria-hidden=\"true\"></i> paper plane o"
                },
                {
                    "id": "fa-paperclip",
                    "text": "<i class=\"fa fa-2x fa-paperclip\" aria-hidden=\"true\"></i> paperclip"
                },
                {
                    "id": "fa-paragraph",
                    "text": "<i class=\"fa fa-2x fa-paragraph\" aria-hidden=\"true\"></i> paragraph"
                },
                {
                    "id": "fa-paste",
                    "text": "<i class=\"fa fa-2x fa-paste\" aria-hidden=\"true\"></i> paste"
                },
                {
                    "id": "fa-pause",
                    "text": "<i class=\"fa fa-2x fa-pause\" aria-hidden=\"true\"></i> pause"
                },
                {
                    "id": "fa-pause-circle",
                    "text": "<i class=\"fa fa-2x fa-pause-circle\" aria-hidden=\"true\"></i> pause circle"
                },
                {
                    "id": "fa-pause-circle-o",
                    "text": "<i class=\"fa fa-2x fa-pause-circle-o\" aria-hidden=\"true\"></i> pause circle o"
                },
                {
                    "id": "fa-paw",
                    "text": "<i class=\"fa fa-2x fa-paw\" aria-hidden=\"true\"></i> paw"
                },
                {
                    "id": "fa-paypal",
                    "text": "<i class=\"fa fa-2x fa-paypal\" aria-hidden=\"true\"></i> paypal"
                },
                {
                    "id": "fa-pencil",
                    "text": "<i class=\"fa fa-2x fa-pencil\" aria-hidden=\"true\"></i> pencil"
                },
                {
                    "id": "fa-pencil-square",
                    "text": "<i class=\"fa fa-2x fa-pencil-square\" aria-hidden=\"true\"></i> pencil square"
                },
                {
                    "id": "fa-pencil-square-o",
                    "text": "<i class=\"fa fa-2x fa-pencil-square-o\" aria-hidden=\"true\"></i> pencil square o"
                },
                {
                    "id": "fa-percent",
                    "text": "<i class=\"fa fa-2x fa-percent\" aria-hidden=\"true\"></i> percent"
                },
                {
                    "id": "fa-phone",
                    "text": "<i class=\"fa fa-2x fa-phone\" aria-hidden=\"true\"></i> phone"
                },
                {
                    "id": "fa-phone-square",
                    "text": "<i class=\"fa fa-2x fa-phone-square\" aria-hidden=\"true\"></i> phone square"
                },
                {
                    "id": "fa-photo",
                    "text": "<i class=\"fa fa-2x fa-photo\" aria-hidden=\"true\"></i> photo"
                },
                {
                    "id": "fa-picture-o",
                    "text": "<i class=\"fa fa-2x fa-picture-o\" aria-hidden=\"true\"></i> picture o"
                },
                {
                    "id": "fa-pie-chart",
                    "text": "<i class=\"fa fa-2x fa-pie-chart\" aria-hidden=\"true\"></i> pie chart"
                },
                {
                    "id": "fa-pied-piper",
                    "text": "<i class=\"fa fa-2x fa-pied-piper\" aria-hidden=\"true\"></i> pied piper"
                },
                {
                    "id": "fa-pied-piper-alt",
                    "text": "<i class=\"fa fa-2x fa-pied-piper-alt\" aria-hidden=\"true\"></i> pied piper alt"
                },
                {
                    "id": "fa-pied-piper-pp",
                    "text": "<i class=\"fa fa-2x fa-pied-piper-pp\" aria-hidden=\"true\"></i> pied piper pp"
                },
                {
                    "id": "fa-pinterest",
                    "text": "<i class=\"fa fa-2x fa-pinterest\" aria-hidden=\"true\"></i> pinterest"
                },
                {
                    "id": "fa-pinterest-p",
                    "text": "<i class=\"fa fa-2x fa-pinterest-p\" aria-hidden=\"true\"></i> pinterest p"
                },
                {
                    "id": "fa-pinterest-square",
                    "text": "<i class=\"fa fa-2x fa-pinterest-square\" aria-hidden=\"true\"></i> pinterest square"
                },
                {
                    "id": "fa-plane",
                    "text": "<i class=\"fa fa-2x fa-plane\" aria-hidden=\"true\"></i> plane"
                },
                {
                    "id": "fa-play",
                    "text": "<i class=\"fa fa-2x fa-play\" aria-hidden=\"true\"></i> play"
                },
                {
                    "id": "fa-play-circle",
                    "text": "<i class=\"fa fa-2x fa-play-circle\" aria-hidden=\"true\"></i> play circle"
                },
                {
                    "id": "fa-play-circle-o",
                    "text": "<i class=\"fa fa-2x fa-play-circle-o\" aria-hidden=\"true\"></i> play circle o"
                },
                {
                    "id": "fa-plug",
                    "text": "<i class=\"fa fa-2x fa-plug\" aria-hidden=\"true\"></i> plug"
                },
                {
                    "id": "fa-plus",
                    "text": "<i class=\"fa fa-2x fa-plus\" aria-hidden=\"true\"></i> plus"
                },
                {
                    "id": "fa-plus-circle",
                    "text": "<i class=\"fa fa-2x fa-plus-circle\" aria-hidden=\"true\"></i> plus circle"
                },
                {
                    "id": "fa-plus-square",
                    "text": "<i class=\"fa fa-2x fa-plus-square\" aria-hidden=\"true\"></i> plus square"
                },
                {
                    "id": "fa-plus-square-o",
                    "text": "<i class=\"fa fa-2x fa-plus-square-o\" aria-hidden=\"true\"></i> plus square o"
                },
                {
                    "id": "fa-podcast",
                    "text": "<i class=\"fa fa-2x fa-podcast\" aria-hidden=\"true\"></i> podcast"
                },
                {
                    "id": "fa-power-off",
                    "text": "<i class=\"fa fa-2x fa-power-off\" aria-hidden=\"true\"></i> power off"
                },
                {
                    "id": "fa-print",
                    "text": "<i class=\"fa fa-2x fa-print\" aria-hidden=\"true\"></i> print"
                },
                {
                    "id": "fa-product-hunt",
                    "text": "<i class=\"fa fa-2x fa-product-hunt\" aria-hidden=\"true\"></i> product hunt"
                },
                {
                    "id": "fa-puzzle-piece",
                    "text": "<i class=\"fa fa-2x fa-puzzle-piece\" aria-hidden=\"true\"></i> puzzle piece"
                },
                {
                    "id": "fa-qq",
                    "text": "<i class=\"fa fa-2x fa-qq\" aria-hidden=\"true\"></i> qq"
                },
                {
                    "id": "fa-qrcode",
                    "text": "<i class=\"fa fa-2x fa-qrcode\" aria-hidden=\"true\"></i> qrcode"
                },
                {
                    "id": "fa-question",
                    "text": "<i class=\"fa fa-2x fa-question\" aria-hidden=\"true\"></i> question"
                },
                {
                    "id": "fa-question-circle",
                    "text": "<i class=\"fa fa-2x fa-question-circle\" aria-hidden=\"true\"></i> question circle"
                },
                {
                    "id": "fa-question-circle-o",
                    "text": "<i class=\"fa fa-2x fa-question-circle-o\" aria-hidden=\"true\"></i> question circle o"
                },
                {
                    "id": "fa-quora",
                    "text": "<i class=\"fa fa-2x fa-quora\" aria-hidden=\"true\"></i> quora"
                },
                {
                    "id": "fa-quote-left",
                    "text": "<i class=\"fa fa-2x fa-quote-left\" aria-hidden=\"true\"></i> quote left"
                },
                {
                    "id": "fa-quote-right",
                    "text": "<i class=\"fa fa-2x fa-quote-right\" aria-hidden=\"true\"></i> quote right"
                },
                {
                    "id": "fa-ra",
                    "text": "<i class=\"fa fa-2x fa-ra\" aria-hidden=\"true\"></i> ra"
                },
                {
                    "id": "fa-random",
                    "text": "<i class=\"fa fa-2x fa-random\" aria-hidden=\"true\"></i> random"
                },
                {
                    "id": "fa-ravelry",
                    "text": "<i class=\"fa fa-2x fa-ravelry\" aria-hidden=\"true\"></i> ravelry"
                },
                {
                    "id": "fa-rebel",
                    "text": "<i class=\"fa fa-2x fa-rebel\" aria-hidden=\"true\"></i> rebel"
                },
                {
                    "id": "fa-recycle",
                    "text": "<i class=\"fa fa-2x fa-recycle\" aria-hidden=\"true\"></i> recycle"
                },
                {
                    "id": "fa-reddit",
                    "text": "<i class=\"fa fa-2x fa-reddit\" aria-hidden=\"true\"></i> reddit"
                },
                {
                    "id": "fa-reddit-alien",
                    "text": "<i class=\"fa fa-2x fa-reddit-alien\" aria-hidden=\"true\"></i> reddit alien"
                },
                {
                    "id": "fa-reddit-square",
                    "text": "<i class=\"fa fa-2x fa-reddit-square\" aria-hidden=\"true\"></i> reddit square"
                },
                {
                    "id": "fa-refresh",
                    "text": "<i class=\"fa fa-2x fa-refresh\" aria-hidden=\"true\"></i> refresh"
                },
                {
                    "id": "fa-registered",
                    "text": "<i class=\"fa fa-2x fa-registered\" aria-hidden=\"true\"></i> registered"
                },
                {
                    "id": "fa-remove",
                    "text": "<i class=\"fa fa-2x fa-remove\" aria-hidden=\"true\"></i> remove"
                },
                {
                    "id": "fa-renren",
                    "text": "<i class=\"fa fa-2x fa-renren\" aria-hidden=\"true\"></i> renren"
                },
                {
                    "id": "fa-reorder",
                    "text": "<i class=\"fa fa-2x fa-reorder\" aria-hidden=\"true\"></i> reorder"
                },
                {
                    "id": "fa-repeat",
                    "text": "<i class=\"fa fa-2x fa-repeat\" aria-hidden=\"true\"></i> repeat"
                },
                {
                    "id": "fa-reply",
                    "text": "<i class=\"fa fa-2x fa-reply\" aria-hidden=\"true\"></i> reply"
                },
                {
                    "id": "fa-reply-all",
                    "text": "<i class=\"fa fa-2x fa-reply-all\" aria-hidden=\"true\"></i> reply all"
                },
                {
                    "id": "fa-resistance",
                    "text": "<i class=\"fa fa-2x fa-resistance\" aria-hidden=\"true\"></i> resistance"
                },
                {
                    "id": "fa-retweet",
                    "text": "<i class=\"fa fa-2x fa-retweet\" aria-hidden=\"true\"></i> retweet"
                },
                {
                    "id": "fa-rmb",
                    "text": "<i class=\"fa fa-2x fa-rmb\" aria-hidden=\"true\"></i> rmb"
                },
                {
                    "id": "fa-road",
                    "text": "<i class=\"fa fa-2x fa-road\" aria-hidden=\"true\"></i> road"
                },
                {
                    "id": "fa-rocket",
                    "text": "<i class=\"fa fa-2x fa-rocket\" aria-hidden=\"true\"></i> rocket"
                },
                {
                    "id": "fa-rotate-left",
                    "text": "<i class=\"fa fa-2x fa-rotate-left\" aria-hidden=\"true\"></i> rotate left"
                },
                {
                    "id": "fa-rotate-right",
                    "text": "<i class=\"fa fa-2x fa-rotate-right\" aria-hidden=\"true\"></i> rotate right"
                },
                {
                    "id": "fa-rouble",
                    "text": "<i class=\"fa fa-2x fa-rouble\" aria-hidden=\"true\"></i> rouble"
                },
                {
                    "id": "fa-rss",
                    "text": "<i class=\"fa fa-2x fa-rss\" aria-hidden=\"true\"></i> rss"
                },
                {
                    "id": "fa-rss-square",
                    "text": "<i class=\"fa fa-2x fa-rss-square\" aria-hidden=\"true\"></i> rss square"
                },
                {
                    "id": "fa-rub",
                    "text": "<i class=\"fa fa-2x fa-rub\" aria-hidden=\"true\"></i> rub"
                },
                {
                    "id": "fa-ruble",
                    "text": "<i class=\"fa fa-2x fa-ruble\" aria-hidden=\"true\"></i> ruble"
                },
                {
                    "id": "fa-rupee",
                    "text": "<i class=\"fa fa-2x fa-rupee\" aria-hidden=\"true\"></i> rupee"
                },
                {
                    "id": "fa-s15",
                    "text": "<i class=\"fa fa-2x fa-s15\" aria-hidden=\"true\"></i> s15"
                },
                {
                    "id": "fa-safari",
                    "text": "<i class=\"fa fa-2x fa-safari\" aria-hidden=\"true\"></i> safari"
                },
                {
                    "id": "fa-save",
                    "text": "<i class=\"fa fa-2x fa-save\" aria-hidden=\"true\"></i> save"
                },
                {
                    "id": "fa-scissors",
                    "text": "<i class=\"fa fa-2x fa-scissors\" aria-hidden=\"true\"></i> scissors"
                },
                {
                    "id": "fa-scribd",
                    "text": "<i class=\"fa fa-2x fa-scribd\" aria-hidden=\"true\"></i> scribd"
                },
                {
                    "id": "fa-search",
                    "text": "<i class=\"fa fa-2x fa-search\" aria-hidden=\"true\"></i> search"
                },
                {
                    "id": "fa-search-minus",
                    "text": "<i class=\"fa fa-2x fa-search-minus\" aria-hidden=\"true\"></i> search minus"
                },
                {
                    "id": "fa-search-plus",
                    "text": "<i class=\"fa fa-2x fa-search-plus\" aria-hidden=\"true\"></i> search plus"
                },
                {
                    "id": "fa-sellsy",
                    "text": "<i class=\"fa fa-2x fa-sellsy\" aria-hidden=\"true\"></i> sellsy"
                },
                {
                    "id": "fa-send",
                    "text": "<i class=\"fa fa-2x fa-send\" aria-hidden=\"true\"></i> send"
                },
                {
                    "id": "fa-send-o",
                    "text": "<i class=\"fa fa-2x fa-send-o\" aria-hidden=\"true\"></i> send o"
                },
                {
                    "id": "fa-server",
                    "text": "<i class=\"fa fa-2x fa-server\" aria-hidden=\"true\"></i> server"
                },
                {
                    "id": "fa-share",
                    "text": "<i class=\"fa fa-2x fa-share\" aria-hidden=\"true\"></i> share"
                },
                {
                    "id": "fa-share-alt",
                    "text": "<i class=\"fa fa-2x fa-share-alt\" aria-hidden=\"true\"></i> share alt"
                },
                {
                    "id": "fa-share-alt-square",
                    "text": "<i class=\"fa fa-2x fa-share-alt-square\" aria-hidden=\"true\"></i> share alt square"
                },
                {
                    "id": "fa-share-square",
                    "text": "<i class=\"fa fa-2x fa-share-square\" aria-hidden=\"true\"></i> share square"
                },
                {
                    "id": "fa-share-square-o",
                    "text": "<i class=\"fa fa-2x fa-share-square-o\" aria-hidden=\"true\"></i> share square o"
                },
                {
                    "id": "fa-shekel",
                    "text": "<i class=\"fa fa-2x fa-shekel\" aria-hidden=\"true\"></i> shekel"
                },
                {
                    "id": "fa-sheqel",
                    "text": "<i class=\"fa fa-2x fa-sheqel\" aria-hidden=\"true\"></i> sheqel"
                },
                {
                    "id": "fa-shield",
                    "text": "<i class=\"fa fa-2x fa-shield\" aria-hidden=\"true\"></i> shield"
                },
                {
                    "id": "fa-ship",
                    "text": "<i class=\"fa fa-2x fa-ship\" aria-hidden=\"true\"></i> ship"
                },
                {
                    "id": "fa-shirtsinbulk",
                    "text": "<i class=\"fa fa-2x fa-shirtsinbulk\" aria-hidden=\"true\"></i> shirtsinbulk"
                },
                {
                    "id": "fa-shopping-bag",
                    "text": "<i class=\"fa fa-2x fa-shopping-bag\" aria-hidden=\"true\"></i> shopping bag"
                },
                {
                    "id": "fa-shopping-basket",
                    "text": "<i class=\"fa fa-2x fa-shopping-basket\" aria-hidden=\"true\"></i> shopping basket"
                },
                {
                    "id": "fa-shopping-cart",
                    "text": "<i class=\"fa fa-2x fa-shopping-cart\" aria-hidden=\"true\"></i> shopping cart"
                },
                {
                    "id": "fa-shower",
                    "text": "<i class=\"fa fa-2x fa-shower\" aria-hidden=\"true\"></i> shower"
                },
                {
                    "id": "fa-sign-in",
                    "text": "<i class=\"fa fa-2x fa-sign-in\" aria-hidden=\"true\"></i> sign in"
                },
                {
                    "id": "fa-sign-language",
                    "text": "<i class=\"fa fa-2x fa-sign-language\" aria-hidden=\"true\"></i> sign language"
                },
                {
                    "id": "fa-sign-out",
                    "text": "<i class=\"fa fa-2x fa-sign-out\" aria-hidden=\"true\"></i> sign out"
                },
                {
                    "id": "fa-signal",
                    "text": "<i class=\"fa fa-2x fa-signal\" aria-hidden=\"true\"></i> signal"
                },
                {
                    "id": "fa-signing",
                    "text": "<i class=\"fa fa-2x fa-signing\" aria-hidden=\"true\"></i> signing"
                },
                {
                    "id": "fa-simplybuilt",
                    "text": "<i class=\"fa fa-2x fa-simplybuilt\" aria-hidden=\"true\"></i> simplybuilt"
                },
                {
                    "id": "fa-sitemap",
                    "text": "<i class=\"fa fa-2x fa-sitemap\" aria-hidden=\"true\"></i> sitemap"
                },
                {
                    "id": "fa-skyatlas",
                    "text": "<i class=\"fa fa-2x fa-skyatlas\" aria-hidden=\"true\"></i> skyatlas"
                },
                {
                    "id": "fa-skype",
                    "text": "<i class=\"fa fa-2x fa-skype\" aria-hidden=\"true\"></i> skype"
                },
                {
                    "id": "fa-slack",
                    "text": "<i class=\"fa fa-2x fa-slack\" aria-hidden=\"true\"></i> slack"
                },
                {
                    "id": "fa-sliders",
                    "text": "<i class=\"fa fa-2x fa-sliders\" aria-hidden=\"true\"></i> sliders"
                },
                {
                    "id": "fa-slideshare",
                    "text": "<i class=\"fa fa-2x fa-slideshare\" aria-hidden=\"true\"></i> slideshare"
                },
                {
                    "id": "fa-smile-o",
                    "text": "<i class=\"fa fa-2x fa-smile-o\" aria-hidden=\"true\"></i> smile o"
                },
                {
                    "id": "fa-snapchat",
                    "text": "<i class=\"fa fa-2x fa-snapchat\" aria-hidden=\"true\"></i> snapchat"
                },
                {
                    "id": "fa-snapchat-ghost",
                    "text": "<i class=\"fa fa-2x fa-snapchat-ghost\" aria-hidden=\"true\"></i> snapchat ghost"
                },
                {
                    "id": "fa-snapchat-square",
                    "text": "<i class=\"fa fa-2x fa-snapchat-square\" aria-hidden=\"true\"></i> snapchat square"
                },
                {
                    "id": "fa-snowflake-o",
                    "text": "<i class=\"fa fa-2x fa-snowflake-o\" aria-hidden=\"true\"></i> snowflake o"
                },
                {
                    "id": "fa-soccer-ball-o",
                    "text": "<i class=\"fa fa-2x fa-soccer-ball-o\" aria-hidden=\"true\"></i> soccer ball o"
                },
                {
                    "id": "fa-sort",
                    "text": "<i class=\"fa fa-2x fa-sort\" aria-hidden=\"true\"></i> sort"
                },
                {
                    "id": "fa-sort-alpha-asc",
                    "text": "<i class=\"fa fa-2x fa-sort-alpha-asc\" aria-hidden=\"true\"></i> sort alpha asc"
                },
                {
                    "id": "fa-sort-alpha-desc",
                    "text": "<i class=\"fa fa-2x fa-sort-alpha-desc\" aria-hidden=\"true\"></i> sort alpha desc"
                },
                {
                    "id": "fa-sort-amount-asc",
                    "text": "<i class=\"fa fa-2x fa-sort-amount-asc\" aria-hidden=\"true\"></i> sort amount asc"
                },
                {
                    "id": "fa-sort-amount-desc",
                    "text": "<i class=\"fa fa-2x fa-sort-amount-desc\" aria-hidden=\"true\"></i> sort amount desc"
                },
                {
                    "id": "fa-sort-asc",
                    "text": "<i class=\"fa fa-2x fa-sort-asc\" aria-hidden=\"true\"></i> sort asc"
                },
                {
                    "id": "fa-sort-desc",
                    "text": "<i class=\"fa fa-2x fa-sort-desc\" aria-hidden=\"true\"></i> sort desc"
                },
                {
                    "id": "fa-sort-down",
                    "text": "<i class=\"fa fa-2x fa-sort-down\" aria-hidden=\"true\"></i> sort down"
                },
                {
                    "id": "fa-sort-numeric-asc",
                    "text": "<i class=\"fa fa-2x fa-sort-numeric-asc\" aria-hidden=\"true\"></i> sort numeric asc"
                },
                {
                    "id": "fa-sort-numeric-desc",
                    "text": "<i class=\"fa fa-2x fa-sort-numeric-desc\" aria-hidden=\"true\"></i> sort numeric desc"
                },
                {
                    "id": "fa-sort-up",
                    "text": "<i class=\"fa fa-2x fa-sort-up\" aria-hidden=\"true\"></i> sort up"
                },
                {
                    "id": "fa-soundcloud",
                    "text": "<i class=\"fa fa-2x fa-soundcloud\" aria-hidden=\"true\"></i> soundcloud"
                },
                {
                    "id": "fa-space-shuttle",
                    "text": "<i class=\"fa fa-2x fa-space-shuttle\" aria-hidden=\"true\"></i> space shuttle"
                },
                {
                    "id": "fa-spinner",
                    "text": "<i class=\"fa fa-2x fa-spinner\" aria-hidden=\"true\"></i> spinner"
                },
                {
                    "id": "fa-spoon",
                    "text": "<i class=\"fa fa-2x fa-spoon\" aria-hidden=\"true\"></i> spoon"
                },
                {
                    "id": "fa-spotify",
                    "text": "<i class=\"fa fa-2x fa-spotify\" aria-hidden=\"true\"></i> spotify"
                },
                {
                    "id": "fa-square",
                    "text": "<i class=\"fa fa-2x fa-square\" aria-hidden=\"true\"></i> square"
                },
                {
                    "id": "fa-square-o",
                    "text": "<i class=\"fa fa-2x fa-square-o\" aria-hidden=\"true\"></i> square o"
                },
                {
                    "id": "fa-stack-exchange",
                    "text": "<i class=\"fa fa-2x fa-stack-exchange\" aria-hidden=\"true\"></i> stack exchange"
                },
                {
                    "id": "fa-stack-overflow",
                    "text": "<i class=\"fa fa-2x fa-stack-overflow\" aria-hidden=\"true\"></i> stack overflow"
                },
                {
                    "id": "fa-star",
                    "text": "<i class=\"fa fa-2x fa-star\" aria-hidden=\"true\"></i> star"
                },
                {
                    "id": "fa-star-half",
                    "text": "<i class=\"fa fa-2x fa-star-half\" aria-hidden=\"true\"></i> star half"
                },
                {
                    "id": "fa-star-half-empty",
                    "text": "<i class=\"fa fa-2x fa-star-half-empty\" aria-hidden=\"true\"></i> star half empty"
                },
                {
                    "id": "fa-star-half-full",
                    "text": "<i class=\"fa fa-2x fa-star-half-full\" aria-hidden=\"true\"></i> star half full"
                },
                {
                    "id": "fa-star-half-o",
                    "text": "<i class=\"fa fa-2x fa-star-half-o\" aria-hidden=\"true\"></i> star half o"
                },
                {
                    "id": "fa-star-o",
                    "text": "<i class=\"fa fa-2x fa-star-o\" aria-hidden=\"true\"></i> star o"
                },
                {
                    "id": "fa-steam",
                    "text": "<i class=\"fa fa-2x fa-steam\" aria-hidden=\"true\"></i> steam"
                },
                {
                    "id": "fa-steam-square",
                    "text": "<i class=\"fa fa-2x fa-steam-square\" aria-hidden=\"true\"></i> steam square"
                },
                {
                    "id": "fa-step-backward",
                    "text": "<i class=\"fa fa-2x fa-step-backward\" aria-hidden=\"true\"></i> step backward"
                },
                {
                    "id": "fa-step-forward",
                    "text": "<i class=\"fa fa-2x fa-step-forward\" aria-hidden=\"true\"></i> step forward"
                },
                {
                    "id": "fa-stethoscope",
                    "text": "<i class=\"fa fa-2x fa-stethoscope\" aria-hidden=\"true\"></i> stethoscope"
                },
                {
                    "id": "fa-sticky-note",
                    "text": "<i class=\"fa fa-2x fa-sticky-note\" aria-hidden=\"true\"></i> sticky note"
                },
                {
                    "id": "fa-sticky-note-o",
                    "text": "<i class=\"fa fa-2x fa-sticky-note-o\" aria-hidden=\"true\"></i> sticky note o"
                },
                {
                    "id": "fa-stop",
                    "text": "<i class=\"fa fa-2x fa-stop\" aria-hidden=\"true\"></i> stop"
                },
                {
                    "id": "fa-stop-circle",
                    "text": "<i class=\"fa fa-2x fa-stop-circle\" aria-hidden=\"true\"></i> stop circle"
                },
                {
                    "id": "fa-stop-circle-o",
                    "text": "<i class=\"fa fa-2x fa-stop-circle-o\" aria-hidden=\"true\"></i> stop circle o"
                },
                {
                    "id": "fa-street-view",
                    "text": "<i class=\"fa fa-2x fa-street-view\" aria-hidden=\"true\"></i> street view"
                },
                {
                    "id": "fa-strikethrough",
                    "text": "<i class=\"fa fa-2x fa-strikethrough\" aria-hidden=\"true\"></i> strikethrough"
                },
                {
                    "id": "fa-stumbleupon",
                    "text": "<i class=\"fa fa-2x fa-stumbleupon\" aria-hidden=\"true\"></i> stumbleupon"
                },
                {
                    "id": "fa-stumbleupon-circle",
                    "text": "<i class=\"fa fa-2x fa-stumbleupon-circle\" aria-hidden=\"true\"></i> stumbleupon circle"
                },
                {
                    "id": "fa-subscript",
                    "text": "<i class=\"fa fa-2x fa-subscript\" aria-hidden=\"true\"></i> subscript"
                },
                {
                    "id": "fa-subway",
                    "text": "<i class=\"fa fa-2x fa-subway\" aria-hidden=\"true\"></i> subway"
                },
                {
                    "id": "fa-suitcase",
                    "text": "<i class=\"fa fa-2x fa-suitcase\" aria-hidden=\"true\"></i> suitcase"
                },
                {
                    "id": "fa-sun-o",
                    "text": "<i class=\"fa fa-2x fa-sun-o\" aria-hidden=\"true\"></i> sun o"
                },
                {
                    "id": "fa-superpowers",
                    "text": "<i class=\"fa fa-2x fa-superpowers\" aria-hidden=\"true\"></i> superpowers"
                },
                {
                    "id": "fa-superscript",
                    "text": "<i class=\"fa fa-2x fa-superscript\" aria-hidden=\"true\"></i> superscript"
                },
                {
                    "id": "fa-support",
                    "text": "<i class=\"fa fa-2x fa-support\" aria-hidden=\"true\"></i> support"
                },
                {
                    "id": "fa-table",
                    "text": "<i class=\"fa fa-2x fa-table\" aria-hidden=\"true\"></i> table"
                },
                {
                    "id": "fa-tablet",
                    "text": "<i class=\"fa fa-2x fa-tablet\" aria-hidden=\"true\"></i> tablet"
                },
                {
                    "id": "fa-tachometer",
                    "text": "<i class=\"fa fa-2x fa-tachometer\" aria-hidden=\"true\"></i> tachometer"
                },
                {
                    "id": "fa-tag",
                    "text": "<i class=\"fa fa-2x fa-tag\" aria-hidden=\"true\"></i> tag"
                },
                {
                    "id": "fa-tags",
                    "text": "<i class=\"fa fa-2x fa-tags\" aria-hidden=\"true\"></i> tags"
                },
                {
                    "id": "fa-tasks",
                    "text": "<i class=\"fa fa-2x fa-tasks\" aria-hidden=\"true\"></i> tasks"
                },
                {
                    "id": "fa-taxi",
                    "text": "<i class=\"fa fa-2x fa-taxi\" aria-hidden=\"true\"></i> taxi"
                },
                {
                    "id": "fa-telegram",
                    "text": "<i class=\"fa fa-2x fa-telegram\" aria-hidden=\"true\"></i> telegram"
                },
                {
                    "id": "fa-television",
                    "text": "<i class=\"fa fa-2x fa-television\" aria-hidden=\"true\"></i> television"
                },
                {
                    "id": "fa-tencent-weibo",
                    "text": "<i class=\"fa fa-2x fa-tencent-weibo\" aria-hidden=\"true\"></i> tencent weibo"
                },
                {
                    "id": "fa-terminal",
                    "text": "<i class=\"fa fa-2x fa-terminal\" aria-hidden=\"true\"></i> terminal"
                },
                {
                    "id": "fa-text-height",
                    "text": "<i class=\"fa fa-2x fa-text-height\" aria-hidden=\"true\"></i> text height"
                },
                {
                    "id": "fa-text-width",
                    "text": "<i class=\"fa fa-2x fa-text-width\" aria-hidden=\"true\"></i> text width"
                },
                {
                    "id": "fa-th",
                    "text": "<i class=\"fa fa-2x fa-th\" aria-hidden=\"true\"></i> th"
                },
                {
                    "id": "fa-th-large",
                    "text": "<i class=\"fa fa-2x fa-th-large\" aria-hidden=\"true\"></i> th large"
                },
                {
                    "id": "fa-th-list",
                    "text": "<i class=\"fa fa-2x fa-th-list\" aria-hidden=\"true\"></i> th list"
                },
                {
                    "id": "fa-themeisle",
                    "text": "<i class=\"fa fa-2x fa-themeisle\" aria-hidden=\"true\"></i> themeisle"
                },
                {
                    "id": "fa-thermometer",
                    "text": "<i class=\"fa fa-2x fa-thermometer\" aria-hidden=\"true\"></i> thermometer"
                },
                {
                    "id": "fa-thermometer-0",
                    "text": "<i class=\"fa fa-2x fa-thermometer-0\" aria-hidden=\"true\"></i> thermometer 0"
                },
                {
                    "id": "fa-thermometer-1",
                    "text": "<i class=\"fa fa-2x fa-thermometer-1\" aria-hidden=\"true\"></i> thermometer 1"
                },
                {
                    "id": "fa-thermometer-2",
                    "text": "<i class=\"fa fa-2x fa-thermometer-2\" aria-hidden=\"true\"></i> thermometer 2"
                },
                {
                    "id": "fa-thermometer-3",
                    "text": "<i class=\"fa fa-2x fa-thermometer-3\" aria-hidden=\"true\"></i> thermometer 3"
                },
                {
                    "id": "fa-thermometer-4",
                    "text": "<i class=\"fa fa-2x fa-thermometer-4\" aria-hidden=\"true\"></i> thermometer 4"
                },
                {
                    "id": "fa-thermometer-empty",
                    "text": "<i class=\"fa fa-2x fa-thermometer-empty\" aria-hidden=\"true\"></i> thermometer empty"
                },
                {
                    "id": "fa-thermometer-full",
                    "text": "<i class=\"fa fa-2x fa-thermometer-full\" aria-hidden=\"true\"></i> thermometer full"
                },
                {
                    "id": "fa-thermometer-half",
                    "text": "<i class=\"fa fa-2x fa-thermometer-half\" aria-hidden=\"true\"></i> thermometer half"
                },
                {
                    "id": "fa-thermometer-quarter",
                    "text": "<i class=\"fa fa-2x fa-thermometer-quarter\" aria-hidden=\"true\"></i> thermometer quarter"
                },
                {
                    "id": "fa-thermometer-three-quarters",
                    "text": "<i class=\"fa fa-2x fa-thermometer-three-quarters\" aria-hidden=\"true\"></i> thermometer three quarters"
                },
                {
                    "id": "fa-thumb-tack",
                    "text": "<i class=\"fa fa-2x fa-thumb-tack\" aria-hidden=\"true\"></i> thumb tack"
                },
                {
                    "id": "fa-thumbs-down",
                    "text": "<i class=\"fa fa-2x fa-thumbs-down\" aria-hidden=\"true\"></i> thumbs down"
                },
                {
                    "id": "fa-thumbs-o-down",
                    "text": "<i class=\"fa fa-2x fa-thumbs-o-down\" aria-hidden=\"true\"></i> thumbs o down"
                },
                {
                    "id": "fa-thumbs-o-up",
                    "text": "<i class=\"fa fa-2x fa-thumbs-o-up\" aria-hidden=\"true\"></i> thumbs o up"
                },
                {
                    "id": "fa-thumbs-up",
                    "text": "<i class=\"fa fa-2x fa-thumbs-up\" aria-hidden=\"true\"></i> thumbs up"
                },
                {
                    "id": "fa-ticket",
                    "text": "<i class=\"fa fa-2x fa-ticket\" aria-hidden=\"true\"></i> ticket"
                },
                {
                    "id": "fa-times",
                    "text": "<i class=\"fa fa-2x fa-times\" aria-hidden=\"true\"></i> times"
                },
                {
                    "id": "fa-times-circle",
                    "text": "<i class=\"fa fa-2x fa-times-circle\" aria-hidden=\"true\"></i> times circle"
                },
                {
                    "id": "fa-times-circle-o",
                    "text": "<i class=\"fa fa-2x fa-times-circle-o\" aria-hidden=\"true\"></i> times circle o"
                },
                {
                    "id": "fa-times-rectangle",
                    "text": "<i class=\"fa fa-2x fa-times-rectangle\" aria-hidden=\"true\"></i> times rectangle"
                },
                {
                    "id": "fa-times-rectangle-o",
                    "text": "<i class=\"fa fa-2x fa-times-rectangle-o\" aria-hidden=\"true\"></i> times rectangle o"
                },
                {
                    "id": "fa-tint",
                    "text": "<i class=\"fa fa-2x fa-tint\" aria-hidden=\"true\"></i> tint"
                },
                {
                    "id": "fa-toggle-down",
                    "text": "<i class=\"fa fa-2x fa-toggle-down\" aria-hidden=\"true\"></i> toggle down"
                },
                {
                    "id": "fa-toggle-left",
                    "text": "<i class=\"fa fa-2x fa-toggle-left\" aria-hidden=\"true\"></i> toggle left"
                },
                {
                    "id": "fa-toggle-off",
                    "text": "<i class=\"fa fa-2x fa-toggle-off\" aria-hidden=\"true\"></i> toggle off"
                },
                {
                    "id": "fa-toggle-on",
                    "text": "<i class=\"fa fa-2x fa-toggle-on\" aria-hidden=\"true\"></i> toggle on"
                },
                {
                    "id": "fa-toggle-right",
                    "text": "<i class=\"fa fa-2x fa-toggle-right\" aria-hidden=\"true\"></i> toggle right"
                },
                {
                    "id": "fa-toggle-up",
                    "text": "<i class=\"fa fa-2x fa-toggle-up\" aria-hidden=\"true\"></i> toggle up"
                },
                {
                    "id": "fa-trademark",
                    "text": "<i class=\"fa fa-2x fa-trademark\" aria-hidden=\"true\"></i> trademark"
                },
                {
                    "id": "fa-train",
                    "text": "<i class=\"fa fa-2x fa-train\" aria-hidden=\"true\"></i> train"
                },
                {
                    "id": "fa-transgender",
                    "text": "<i class=\"fa fa-2x fa-transgender\" aria-hidden=\"true\"></i> transgender"
                },
                {
                    "id": "fa-transgender-alt",
                    "text": "<i class=\"fa fa-2x fa-transgender-alt\" aria-hidden=\"true\"></i> transgender alt"
                },
                {
                    "id": "fa-trash",
                    "text": "<i class=\"fa fa-2x fa-trash\" aria-hidden=\"true\"></i> trash"
                },
                {
                    "id": "fa-trash-o",
                    "text": "<i class=\"fa fa-2x fa-trash-o\" aria-hidden=\"true\"></i> trash o"
                },
                {
                    "id": "fa-tree",
                    "text": "<i class=\"fa fa-2x fa-tree\" aria-hidden=\"true\"></i> tree"
                },
                {
                    "id": "fa-trello",
                    "text": "<i class=\"fa fa-2x fa-trello\" aria-hidden=\"true\"></i> trello"
                },
                {
                    "id": "fa-tripadvisor",
                    "text": "<i class=\"fa fa-2x fa-tripadvisor\" aria-hidden=\"true\"></i> tripadvisor"
                },
                {
                    "id": "fa-trophy",
                    "text": "<i class=\"fa fa-2x fa-trophy\" aria-hidden=\"true\"></i> trophy"
                },
                {
                    "id": "fa-truck",
                    "text": "<i class=\"fa fa-2x fa-truck\" aria-hidden=\"true\"></i> truck"
                },
                {
                    "id": "fa-try",
                    "text": "<i class=\"fa fa-2x fa-try\" aria-hidden=\"true\"></i> try"
                },
                {
                    "id": "fa-tty",
                    "text": "<i class=\"fa fa-2x fa-tty\" aria-hidden=\"true\"></i> tty"
                },
                {
                    "id": "fa-tumblr",
                    "text": "<i class=\"fa fa-2x fa-tumblr\" aria-hidden=\"true\"></i> tumblr"
                },
                {
                    "id": "fa-tumblr-square",
                    "text": "<i class=\"fa fa-2x fa-tumblr-square\" aria-hidden=\"true\"></i> tumblr square"
                },
                {
                    "id": "fa-turkish-lira",
                    "text": "<i class=\"fa fa-2x fa-turkish-lira\" aria-hidden=\"true\"></i> turkish lira"
                },
                {
                    "id": "fa-tv",
                    "text": "<i class=\"fa fa-2x fa-tv\" aria-hidden=\"true\"></i> tv"
                },
                {
                    "id": "fa-twitch",
                    "text": "<i class=\"fa fa-2x fa-twitch\" aria-hidden=\"true\"></i> twitch"
                },
                {
                    "id": "fa-twitter",
                    "text": "<i class=\"fa fa-2x fa-twitter\" aria-hidden=\"true\"></i> twitter"
                },
                {
                    "id": "fa-twitter-square",
                    "text": "<i class=\"fa fa-2x fa-twitter-square\" aria-hidden=\"true\"></i> twitter square"
                },
                {
                    "id": "fa-umbrella",
                    "text": "<i class=\"fa fa-2x fa-umbrella\" aria-hidden=\"true\"></i> umbrella"
                },
                {
                    "id": "fa-underline",
                    "text": "<i class=\"fa fa-2x fa-underline\" aria-hidden=\"true\"></i> underline"
                },
                {
                    "id": "fa-undo",
                    "text": "<i class=\"fa fa-2x fa-undo\" aria-hidden=\"true\"></i> undo"
                },
                {
                    "id": "fa-universal-access",
                    "text": "<i class=\"fa fa-2x fa-universal-access\" aria-hidden=\"true\"></i> universal access"
                },
                {
                    "id": "fa-university",
                    "text": "<i class=\"fa fa-2x fa-university\" aria-hidden=\"true\"></i> university"
                },
                {
                    "id": "fa-unlink",
                    "text": "<i class=\"fa fa-2x fa-unlink\" aria-hidden=\"true\"></i> unlink"
                },
                {
                    "id": "fa-unlock",
                    "text": "<i class=\"fa fa-2x fa-unlock\" aria-hidden=\"true\"></i> unlock"
                },
                {
                    "id": "fa-unlock-alt",
                    "text": "<i class=\"fa fa-2x fa-unlock-alt\" aria-hidden=\"true\"></i> unlock alt"
                },
                {
                    "id": "fa-unsorted",
                    "text": "<i class=\"fa fa-2x fa-unsorted\" aria-hidden=\"true\"></i> unsorted"
                },
                {
                    "id": "fa-upload",
                    "text": "<i class=\"fa fa-2x fa-upload\" aria-hidden=\"true\"></i> upload"
                },
                {
                    "id": "fa-usb",
                    "text": "<i class=\"fa fa-2x fa-usb\" aria-hidden=\"true\"></i> usb"
                },
                {
                    "id": "fa-usd",
                    "text": "<i class=\"fa fa-2x fa-usd\" aria-hidden=\"true\"></i> usd"
                },
                {
                    "id": "fa-user",
                    "text": "<i class=\"fa fa-2x fa-user\" aria-hidden=\"true\"></i> user"
                },
                {
                    "id": "fa-user-circle",
                    "text": "<i class=\"fa fa-2x fa-user-circle\" aria-hidden=\"true\"></i> user circle"
                },
                {
                    "id": "fa-user-circle-o",
                    "text": "<i class=\"fa fa-2x fa-user-circle-o\" aria-hidden=\"true\"></i> user circle o"
                },
                {
                    "id": "fa-user-md",
                    "text": "<i class=\"fa fa-2x fa-user-md\" aria-hidden=\"true\"></i> user md"
                },
                {
                    "id": "fa-user-o",
                    "text": "<i class=\"fa fa-2x fa-user-o\" aria-hidden=\"true\"></i> user o"
                },
                {
                    "id": "fa-user-plus",
                    "text": "<i class=\"fa fa-2x fa-user-plus\" aria-hidden=\"true\"></i> user plus"
                },
                {
                    "id": "fa-user-secret",
                    "text": "<i class=\"fa fa-2x fa-user-secret\" aria-hidden=\"true\"></i> user secret"
                },
                {
                    "id": "fa-user-times",
                    "text": "<i class=\"fa fa-2x fa-user-times\" aria-hidden=\"true\"></i> user times"
                },
                {
                    "id": "fa-users",
                    "text": "<i class=\"fa fa-2x fa-users\" aria-hidden=\"true\"></i> users"
                },
                {
                    "id": "fa-vcard",
                    "text": "<i class=\"fa fa-2x fa-vcard\" aria-hidden=\"true\"></i> vcard"
                },
                {
                    "id": "fa-vcard-o",
                    "text": "<i class=\"fa fa-2x fa-vcard-o\" aria-hidden=\"true\"></i> vcard o"
                },
                {
                    "id": "fa-venus",
                    "text": "<i class=\"fa fa-2x fa-venus\" aria-hidden=\"true\"></i> venus"
                },
                {
                    "id": "fa-venus-double",
                    "text": "<i class=\"fa fa-2x fa-venus-double\" aria-hidden=\"true\"></i> venus double"
                },
                {
                    "id": "fa-venus-mars",
                    "text": "<i class=\"fa fa-2x fa-venus-mars\" aria-hidden=\"true\"></i> venus mars"
                },
                {
                    "id": "fa-viacoin",
                    "text": "<i class=\"fa fa-2x fa-viacoin\" aria-hidden=\"true\"></i> viacoin"
                },
                {
                    "id": "fa-viadeo",
                    "text": "<i class=\"fa fa-2x fa-viadeo\" aria-hidden=\"true\"></i> viadeo"
                },
                {
                    "id": "fa-viadeo-square",
                    "text": "<i class=\"fa fa-2x fa-viadeo-square\" aria-hidden=\"true\"></i> viadeo square"
                },
                {
                    "id": "fa-video-camera",
                    "text": "<i class=\"fa fa-2x fa-video-camera\" aria-hidden=\"true\"></i> video camera"
                },
                {
                    "id": "fa-vimeo",
                    "text": "<i class=\"fa fa-2x fa-vimeo\" aria-hidden=\"true\"></i> vimeo"
                },
                {
                    "id": "fa-vimeo-square",
                    "text": "<i class=\"fa fa-2x fa-vimeo-square\" aria-hidden=\"true\"></i> vimeo square"
                },
                {
                    "id": "fa-vine",
                    "text": "<i class=\"fa fa-2x fa-vine\" aria-hidden=\"true\"></i> vine"
                },
                {
                    "id": "fa-vk",
                    "text": "<i class=\"fa fa-2x fa-vk\" aria-hidden=\"true\"></i> vk"
                },
                {
                    "id": "fa-volume-control-phone",
                    "text": "<i class=\"fa fa-2x fa-volume-control-phone\" aria-hidden=\"true\"></i> volume control phone"
                },
                {
                    "id": "fa-volume-down",
                    "text": "<i class=\"fa fa-2x fa-volume-down\" aria-hidden=\"true\"></i> volume down"
                },
                {
                    "id": "fa-volume-off",
                    "text": "<i class=\"fa fa-2x fa-volume-off\" aria-hidden=\"true\"></i> volume off"
                },
                {
                    "id": "fa-volume-up",
                    "text": "<i class=\"fa fa-2x fa-volume-up\" aria-hidden=\"true\"></i> volume up"
                },
                {
                    "id": "fa-warning",
                    "text": "<i class=\"fa fa-2x fa-warning\" aria-hidden=\"true\"></i> warning"
                },
                {
                    "id": "fa-wechat",
                    "text": "<i class=\"fa fa-2x fa-wechat\" aria-hidden=\"true\"></i> wechat"
                },
                {
                    "id": "fa-weibo",
                    "text": "<i class=\"fa fa-2x fa-weibo\" aria-hidden=\"true\"></i> weibo"
                },
                {
                    "id": "fa-weixin",
                    "text": "<i class=\"fa fa-2x fa-weixin\" aria-hidden=\"true\"></i> weixin"
                },
                {
                    "id": "fa-whatsapp",
                    "text": "<i class=\"fa fa-2x fa-whatsapp\" aria-hidden=\"true\"></i> whatsapp"
                },
                {
                    "id": "fa-wheelchair",
                    "text": "<i class=\"fa fa-2x fa-wheelchair\" aria-hidden=\"true\"></i> wheelchair"
                },
                {
                    "id": "fa-wheelchair-alt",
                    "text": "<i class=\"fa fa-2x fa-wheelchair-alt\" aria-hidden=\"true\"></i> wheelchair alt"
                },
                {
                    "id": "fa-wifi",
                    "text": "<i class=\"fa fa-2x fa-wifi\" aria-hidden=\"true\"></i> wifi"
                },
                {
                    "id": "fa-wikipedia-w",
                    "text": "<i class=\"fa fa-2x fa-wikipedia-w\" aria-hidden=\"true\"></i> wikipedia w"
                },
                {
                    "id": "fa-window-close",
                    "text": "<i class=\"fa fa-2x fa-window-close\" aria-hidden=\"true\"></i> window close"
                },
                {
                    "id": "fa-window-close-o",
                    "text": "<i class=\"fa fa-2x fa-window-close-o\" aria-hidden=\"true\"></i> window close o"
                },
                {
                    "id": "fa-window-maximize",
                    "text": "<i class=\"fa fa-2x fa-window-maximize\" aria-hidden=\"true\"></i> window maximize"
                },
                {
                    "id": "fa-window-minimize",
                    "text": "<i class=\"fa fa-2x fa-window-minimize\" aria-hidden=\"true\"></i> window minimize"
                },
                {
                    "id": "fa-window-restore",
                    "text": "<i class=\"fa fa-2x fa-window-restore\" aria-hidden=\"true\"></i> window restore"
                },
                {
                    "id": "fa-windows",
                    "text": "<i class=\"fa fa-2x fa-windows\" aria-hidden=\"true\"></i> windows"
                },
                {
                    "id": "fa-won",
                    "text": "<i class=\"fa fa-2x fa-won\" aria-hidden=\"true\"></i> won"
                },
                {
                    "id": "fa-wordpress",
                    "text": "<i class=\"fa fa-2x fa-wordpress\" aria-hidden=\"true\"></i> wordpress"
                },
                {
                    "id": "fa-wpbeginner",
                    "text": "<i class=\"fa fa-2x fa-wpbeginner\" aria-hidden=\"true\"></i> wpbeginner"
                },
                {
                    "id": "fa-wpexplorer",
                    "text": "<i class=\"fa fa-2x fa-wpexplorer\" aria-hidden=\"true\"></i> wpexplorer"
                },
                {
                    "id": "fa-wpforms",
                    "text": "<i class=\"fa fa-2x fa-wpforms\" aria-hidden=\"true\"></i> wpforms"
                },
                {
                    "id": "fa-wrench",
                    "text": "<i class=\"fa fa-2x fa-wrench\" aria-hidden=\"true\"></i> wrench"
                },
                {
                    "id": "fa-xing",
                    "text": "<i class=\"fa fa-2x fa-xing\" aria-hidden=\"true\"></i> xing"
                },
                {
                    "id": "fa-xing-square",
                    "text": "<i class=\"fa fa-2x fa-xing-square\" aria-hidden=\"true\"></i> xing square"
                },
                {
                    "id": "fa-y-combinator",
                    "text": "<i class=\"fa fa-2x fa-y-combinator\" aria-hidden=\"true\"></i> y combinator"
                },
                {
                    "id": "fa-y-combinator-square",
                    "text": "<i class=\"fa fa-2x fa-y-combinator-square\" aria-hidden=\"true\"></i> y combinator square"
                },
                {
                    "id": "fa-yahoo",
                    "text": "<i class=\"fa fa-2x fa-yahoo\" aria-hidden=\"true\"></i> yahoo"
                },
                {
                    "id": "fa-yc",
                    "text": "<i class=\"fa fa-2x fa-yc\" aria-hidden=\"true\"></i> yc"
                },
                {
                    "id": "fa-yc-square",
                    "text": "<i class=\"fa fa-2x fa-yc-square\" aria-hidden=\"true\"></i> yc square"
                },
                {
                    "id": "fa-yelp",
                    "text": "<i class=\"fa fa-2x fa-yelp\" aria-hidden=\"true\"></i> yelp"
                },
                {
                    "id": "fa-yen",
                    "text": "<i class=\"fa fa-2x fa-yen\" aria-hidden=\"true\"></i> yen"
                },
                {
                    "id": "fa-yoast",
                    "text": "<i class=\"fa fa-2x fa-yoast\" aria-hidden=\"true\"></i> yoast"
                },
                {
                    "id": "fa-youtube",
                    "text": "<i class=\"fa fa-2x fa-youtube\" aria-hidden=\"true\"></i> youtube"
                },
                {
                    "id": "fa-youtube-play",
                    "text": "<i class=\"fa fa-2x fa-youtube-play\" aria-hidden=\"true\"></i> youtube play"
                },
                {
                    "id": "fa-youtube-square",
                    "text": "<i class=\"fa fa-2x fa-youtube-square\" aria-hidden=\"true\"></i> youtube square"
                }
            ],
            selectedIcons: [],

        }
    },

    mounted() {
        let self = this;
        // eslint-disable-next-line
        $(this.$refs.editFileModal).on('hidden.bs.modal', function (e) {
            self.close()
        });

    },

    validations: {
        fileData: {
            filename: {
                required,
                maxLength: maxLength(255),
            },
            descricao: {
                maxLength: maxLength(500),
            },
        },

    },
    watch: {
        show() {
            if (this.show) {
                this.fileData = {...this.file};
                // console.log('fileData ', this.fileData)
                if (this.fileData.icons !== null) {
                    this.selectedIcons = this.fileData.icons.split(',');
                }
                this.$v.$reset();
                // eslint-disable-next-line
                $(this.$refs.editFileModal).modal('show');
            } else {
                $(this.$refs.editFileModal).modal('hide');
                this.fileData = {};
            }
        },
    },

    methods: {
        close() {
            this.$emit('update:show', false);
        },

        editar() {
            // console.debug('editar', this.fileData)

            this.$v.$touch();
            if (this.$v.$invalid) {
                // console.error('data invalid');
                return;
            }

            if (this.selectedIcons && this.selectedIcons.length > 0) {
                // console.debug('selectedIcons ', this.selectedIcons)
                this.fileData.icons = this.selectedIcons.join(',');
            }

            // eslint-disable-next-line
            const url = jsRoutes.controllers.ImpAcessoriosController.editAcessorioFicheiroData().url;

            this.loading = true;
            axios.post(url, this.fileData)
                .then((/*response*/) => {
                    // console.debug('response ', response)
                    this.$emit('refresh');
                    showSuccess("Sucesso", "Dados de ficheiro atualizados");
                    this.$emit('update:show', false);
                })
                .catch(error => {
                    // console.error('error ', error)
                    handleAjaxError("Erro na edição de dados de ficheiro", error);
                })
                .finally(() => {
                    this.loading = false;
                })


        }
    }
}
</script>


<style scoped>

</style>
