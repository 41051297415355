<template>

    <div class="modal" tabindex="-1" role="dialog" ref="modal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button>
                    <h5 class="modal-title">Criar Categoria</h5>
                </div>

                <div class="modal-body">

                    <div class="form-group" :class="{'has-error' : $v.nome.$error}">
                        <label>Categoria</label>
                        <input type="text" class="form-control"
                               v-model="nome">
                        <span class="help-block"
                              v-show="!$v.nome.required && $v.nome.$error">
                            Campo obrigatório
                        </span>
                        <span class="help-block"
                              v-show="!$v.nome.maxLength && $v.nome.$error">
                            O campo tem de ter menos de {{ $v.nome.$params.maxLength.max }} caracteres
                        </span>
                    </div>


                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
                    <v-button type="button"
                              :loading="saving"
                              @click="criar"
                              class="btn btn-primary">
                        Criar
                    </v-button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import VButton from "@/components/VButton.vue";
import {maxLength, required} from "vuelidate/lib/validators";
import axios from "axios";
import {showSuccess, handleAjaxError} from "@/utils";

export default {
    name: "CriarCategoriaModal",
    props: ['show'],
    components: {VButton},
    mounted() {
        let self = this;
        // eslint-disable-next-line
        $(this.$refs.modal).on('hidden.bs.modal', function (e) {
            self.close()
        });

    },

    data() {
        return {
            saving: false,
            nome: '',
        }
    },

    validations: {
        nome: {
            required,
            maxLength: maxLength(100),
        },
    },

    watch: {
        show() {
            if (this.show) {
                this.nome = '';
                // eslint-disable-next-line
                $(this.$refs.modal).modal('show');
            } else {
                $(this.$refs.modal).modal('hide');
            }
        },
    },

    methods: {
        close() {
            this.$emit('update:show', false);
        },

        criar(){
            this.$v.$touch()
            if (this.$v.$invalid) {
                // console.error('data invalid');
                return;
            }

            console.debug('we create now')

            this.saving = true;
            // eslint-disable-next-line
            const url = jsRoutes.controllers.ImpAcessoriosController.criarCategoriaAcessorio().url;
            axios.post(url, {nome: this.nome})
                .then((/*response*/)=>{
                    // console.debug(response)
                    this.$emit('refresh')
                    showSuccess('Sucesso!', 'Categoria de acessório criada!')
                    this.$emit('update:show')
                })
                .catch(error=>{
                    // console.error(error)
                    handleAjaxError('Erro', error);
                })
                .finally(()=>{
                    this.saving = false;
                })
        }
    },
}
</script>


<style scoped>

</style>
