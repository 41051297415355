<template>
    <div class="row">
        <div class="col-sm-12">
            <div class="panel panel-default">

                <div class="panel-heading">
                    <h1>Editar Notificação</h1>
                </div>

                <div class="panel-body">
                    <div class="row">
                        <div class="col-sm-12">

                            <form novalidate>

                                <div class="form-group" :class="{'has-error' : $v.data.titulo.$error}">
                                    <label>Título *</label>
                                    <input type="text" class="form-control" v-model="data.titulo"/>
                                    <span class="help-block"
                                          v-show="!$v.data.titulo.required && $v.data.titulo.$error">
                                        Campo obrigatório
                                    </span>
                                    <span class="help-block"
                                          v-show="!$v.data.titulo.maxLength && $v.data.titulo.$error">
                                        O campo tem de ter menos de {{ $v.data.titulo.$params.maxLength.max }} caracteres
                                    </span>
                                </div>

                                <div class="form-group" :class="{'has-error' : $v.data.texto.$error}">
                                    <label>Texto *</label>
                                    <input type="hidden" class="form-control" v-model="data.texto"/>
                                    <vue-editor v-model="data.texto" :editorToolbar="customToolbar"/>
                                    <span class="help-block"
                                          v-show="!$v.data.texto.required && $v.data.texto.$error">
                                        Campo obrigatório
                                    </span>
                                </div>

                                <div class="form-group" :class="{'has-error' : $v.data.profileIds.$error}">
                                    <label>Perfil *</label>
                                    <b-select :value.sync="data.profileIds"
                                              :items="profiles"
                                              multiple
                                              class="form-control"></b-select>
                                    <span class="help-block"
                                          v-show="!$v.data.profileIds.required && $v.data.profileIds.$error">
                                        Campo obrigatório
                                    </span>
                                </div>

                                <div class="form-group">
                                    <label>Ficheiro</label>
                                    <div v-show="!hasFile" class="dropzone" ref="dropzonearea">
                                        <div class="dz-message">
                                            <h3>Arrastar ficheiros para carregar</h3><br>
                                            <span class="note">(Ou clicar com o rato para selecionar ficheiros)</span>
                                        </div>
                                    </div>
                                    <br/>
                                    <br/>
                                    <span v-if="hasFile" class="row">
                                        <div class="col-sm-5">
                                            <a :href="downloaFileUrl">
                                                {{ data.filename }}
                                            </a>
                                        </div>
                                        <div class="col-sm-5">
                                            <button type="button"
                                                    @click="deleteNotificacaoFile"
                                                    class="btn btn-sm btn-danger">
                                                <i class="fa fa-trash-o"></i>
                                            </button>
                                        </div>
                                    </span>
                                </div>

                            </form>


                            <h5>Notificação Lida por:</h5>

                            <div class="table-responsive" style="font-size: smaller;">
                                <table class="table table-bordered table-striped table-hover"
                                       id="imp_notificacoes_lidas_table">
                                    <thead>
                                    <tr>
                                        <th>Data Leitura</th>
                                        <th>Utilizador</th>
                                        <th>Concessionário</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="item in data.lidas" :key="item.id">
                                        <td>{{ item.data }}</td>
                                        <td>{{ item.userName }}</td>
                                        <td>{{ item.concessionario }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>


                            <div class="pull-right">
                                <router-link :to="{name: 'ImpNotificacoesHome'}">
                                    Cancelar
                                </router-link>

                                <v-button type="button" class="btn btn-primary"
                                          :loading="loading"
                                          @click="editarNotificacao">
                                    Editar Notificação
                                </v-button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {maxLength, required} from "vuelidate/lib/validators";
import Dropzone from "dropzone";
import {VueEditor} from "vue2-editor";

import BSelect from "@/components/BSelect.vue";
import VButton from "@/components/VButton.vue";
import {datatableLanguage, handleAjaxError, showCustomDialog, showSuccess} from "@/utils";

Dropzone.autoDiscover = false;


export default {
    name: "ImpNotificacoesEdit",
    props: ['id'],
    components: {VButton, BSelect, VueEditor},

    data() {
        return {
            loading: false,
            data: {
                id: null,
                titulo: '',
                texto: '',
                profileIds: [],
                filename: '',
                sysfilename: '',
            },
            profiles: [],
            customToolbar: [
                ["bold", "italic", "underline"],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}],
                [{'indent': '-1'}, {'indent': '+1'}],
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                ['clean']
            ]
        }
    },

    mounted() {
        // console.debug('item id', this.id);
        this.setupDropzone();
        this.loadProfiles();
        this.loadNotificacao();
    },

    validations: {
        data: {
            titulo: {
                required,
                maxLength: maxLength(150),
            },
            texto: {
                required,
            },
            profileIds: {
                required,
            },
        }
    },

    computed: {
        hasFile() {
            return !!this.data.sysfilename;
        },

        downloaFileUrl() {
            if (!this.data.sysfilename) {
                return '#';
            }
            // eslint-disable-next-line
            return jsRoutes.controllers.ImpNotificacoesController.downloadNotificacaoFile(this.id).url
        }
    },

    methods: {

        loadNotificacao() {
            // eslint-disable-next-line
            const url = jsRoutes.controllers.ImpNotificacoesController.loadNotificacao(this.id).url;

            axios.get(url)
                .then((response) => {
                    // console.debug(response);
                    this.data = response.data;
                    this.$nextTick(() => {
                        this.setupDatatable();
                    });
                })
                .catch(error => {
                    // console.error(error);
                    handleAjaxError(error, 'Erro!');
                })
        },

        editarNotificacao() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                // console.error('data invalid');
                return;
            }

            const files = this.dropzone.files;
            const formData = new FormData();
            if (files[0]) {
                formData.append("file", files[0]);
            }
            formData.append("data", JSON.stringify(this.data));

            this.loading = true;
            // eslint-disable-next-line
            const url = jsRoutes.controllers.ImpNotificacoesController.editarNotificacao().url;

            axios.put(url, formData)
                .then(() => {
                    showSuccess('Sucesso', 'Notificação atualizada!');
                    this.$router.push({name: 'ImpNotificacoesHome'});
                })
                .catch(error => {
                    // console.error(error);
                    handleAjaxError(error, 'Erro!');
                })
                .finally(() => {
                    this.loading = false;
                });

        },

        loadProfiles() {
            // eslint-disable-next-line
            const url = jsRoutes.controllers.ImpNotificacoesController.loadProfiles().url;

            axios.get(url)
                .then(response => {
                    // console.debug(response);
                    this.profiles = response.data;
                })
                .catch(error => {
                    console.error(error);
                })
        },

        setupDropzone() {
            const self = this;
            this.dropzone = new Dropzone(this.$refs.dropzonearea, {
                url: "url", //dropzone.js:434 Uncaught Error: No URL provided
                paramName: "file", // The name that will be used to transfer the file
                maxFilesize: 50, // MB
                autoProcessQueue: false,
                uploadMultiple: false,
                addRemoveLinks: true,
                maxFiles: 1,
                //acceptedFiles: ".jpg, .jpeg, .png, .bmp, .tif, .tiff",
                error: function (file, errorMessage) {//if the error was due to the XMLHttpRequest the xhr object as third
                    // eslint-disable-next-line
                    showError("Erro", errorMessage);
                    // self.uploadSuccess = false;
                    this.removeFile(file);
                    // this.removeAllFiles(true);
                    // self.dropzone.processQueue();
                },

                success: function (file/*, response*/) {
                    // console.log("#### success");
                    // self.uploadSuccess = true;
                    // self.files.push(response);
                    self.dropzone.processQueue();
                    this.removeFile(file);
                },
                /**
                 * If `addRemoveLinks` is true, the text to be used to remove a file.
                 */
                dictRemoveFile: "Remover ficheiro",
                /**
                 * Displayed if `maxFiles` is st and exceeded.
                 * The string `{{maxFiles}}` will be replaced by the configuration value.
                 */
                dictMaxFilesExceeded: "Apenas um ficheiro permitido!",


            });
        },

        deleteNotificacaoFile() {
            showCustomDialog(
                "Tem a certeza?",
                'Tem a certeza que pretende remover o ficheiro?',
                'Remover',
                'btn-danger',
                () => {
                    this.doDeleteFicheiro();
                }
            );
        },

        doDeleteFicheiro() {
            // eslint-disable-next-line
            const url = jsRoutes.controllers.ImpNotificacoesController.deleteNotificacaoFile(this.id).url;

            axios.delete(url)
                .then(() => {
                    // console.debug(response);
                    showSuccess('Sucesso', 'Ficheiro de Notificação removido!');
                    this.data.sysfilename = null;
                })
                .catch(error => {
                    // console.error(error);
                    handleAjaxError(error, 'Erro!');
                })
        },

        setupDatatable() {
            $.fn.dataTable.moment('DD-MM-YYYY HH:mm');

            $('#imp_notificacoes_lidas_table').DataTable({
                "info": false,
                "paging": true,
                "pageLength": 40,
                "searching": true,
                "ordering": true,
                "order": [0, "desc"],
                buttons: [
                    'excel',
                    {
                        extend: 'pdf',
                        orientation: 'landscape',
                        pageSize: 'A4'
                    },
                ],
                "dom": 'Bfrtp',
                "language": datatableLanguage,
            });
        },
    }

}
</script>

<style scoped>

</style>
