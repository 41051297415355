<template>
    <div class="row">
        <div class="col-sm-12">
            <div class="panel panel-default">

                <div class="panel-heading">
                    <h3>Notificações

                        <router-link :to="{name: 'ImpNotificacoesCreate'}"
                                     v-if="writePermission"
                                     class="btn btn-primary">
                            <span class="glyphicon glyphicon-plus"></span>
                            Criar nova
                        </router-link>

                    </h3>
                </div>

                <ul class="list-group">
                    <li class="list-group-item">

                        <div class="table-responsive" style="font-size: smaller;">
                            <table class="table table-bordered table-striped table-hover" id="impNotificacoesTable">
                                <thead>
                                <tr>
                                    <th>Título</th>
                                    <th>Data</th>
                                    <th>Perfil</th>
                                    <th :style="writePermission ? 'width: 200px' : 'width: 65px'" ></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="item in notificacoesList" :key="item.id">
                                    <td>{{ item.titulo }}</td>
                                    <td>{{ item.data }}</td>
                                    <td>{{ item.profileNames.join(", ") }}</td>
                                    <td>
                                        <button class="btn btn-info"
                                                @click="openLogModal(item)"
                                                style="margin-right: 2px">
                                            <i class="fa fa-calendar"></i>
                                            Histórico
                                        </button>
                                        <router-link :to="{name: 'ImpNotificacoesEdit', params:{id: item.id}}"
                                                     v-if="writePermission"
                                                     class="btn btn-primary" style="margin-right: 2px">
                                            <i class="fa fa-edit"></i> Editar
                                        </router-link>
                                        <button class="btn btn-danger"
                                                v-if="writePermission"
                                                @click="deleteNotificacao(item)">
                                            <i class="fa fa-trash-o"></i>
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <notificacoes-lidas-modal :id="logId" :show.sync="showLogModal"></notificacoes-lidas-modal>

    </div>

</template>

<script>
import axios from "axios";

import {datatableLanguage, handleAjaxError, showCustomDialog, showSuccess} from "@/utils";
import NotificacoesLidasModal from "@/components/notificacoesimp/NotificacoesLidasModal.vue";


export default {
    name: "ImpNotificacoesHome",
    components: {NotificacoesLidasModal},

    data() {
        return {
            notificacoesList: [],
            logId: null,
            showLogModal: false,
            writePermission: false,
        }
    },

    mounted() {
        // console.debug('permEsc ', permEsc);
        this.loadList();
        // eslint-disable-next-line
        this.writePermission = permEsc;
    },

    methods: {

        loadList() {
            // eslint-disable-next-line
            const url = jsRoutes.controllers.ImpNotificacoesController.loadNotificacoes().url;

            axios.get(url)
                .then(response => {
                    // console.debug(response);
                    this.notificacoesList = response.data;
                    const dataTable = $('#impNotificacoesTable').DataTable();
                    if (dataTable) {
                        dataTable.destroy();
                    }
                    this.$nextTick(() => {
                        this.setupDatatable();
                    });
                })
                .catch(error => {
                    handleAjaxError(error, 'Erro!');
                })

        },

        setupDatatable() {
            $.fn.dataTable.moment('DD-MM-YYYY');

            $('#impNotificacoesTable').DataTable({
                "info": false,
                "paging": true,
                "pageLength": 40,
                "searching": true,
                "ordering": true,
                "order": [1, "desc"],
                buttons: [
                    'excel',
                    {
                        extend: 'pdf',
                        orientation: 'landscape',
                        pageSize: 'A4'
                    },
                ],
                "dom": 'Bfrtp',
                "language": datatableLanguage,
            });
        },

        deleteNotificacao(notificacao) {
            showCustomDialog(
                "Tem a certeza?",
                `Tem a certeza que pretende remover a notificação '${notificacao.titulo}'?`,
                'Remover',
                'btn-danger',
                () => {
                    this.doDeleteNotificacao(notificacao.id);
                }
            );
        },

        doDeleteNotificacao(id) {
            // eslint-disable-next-line
            const url = jsRoutes.controllers.ImpNotificacoesController.deleteNotificacao(id).url;
            axios.delete(url)
                .then(() => {
                    this.loadList();
                    showSuccess('Sucesso!', 'Notificação removida!')
                })
                .catch(error => {
                    handleAjaxError(error, 'Erro!');
                })

        },

        openLogModal(notificacao) {
            this.logId = notificacao.id;
            this.showLogModal = true;
        }

    }
}
</script>

<style scoped>

</style>
